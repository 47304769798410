import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DashboardCards from "./dashboardCards";
import SideBar from "../Sidebar/sideBar";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
    setCandidateSearchedData, setSkillInfo, fetchSelectedCandidateData,
    setCandiadteSelectedId, networkErrorDialogToggle, setCandidateCount,
    setCandidateLimit, setCandiadteMaster, setClearExperienceLoader,
    setSearchStatusTrigger, setReleventTitleStatus, searchDataDialogToggle
} from "../../redux";
import { connect } from "react-redux";
import { jobFilter, getCandidate, totalCount, jobFilterV1 } from "../../_service/jobListing";

import DashboardSearch from "./dashboardSearch";
import SearchDialog from "../Dialog/SearchDialog";
import FilteredByDialog from "../Dialog/FilteredByDialog";

function Dashboard(props) {
    const [experience, setExperience] = useState([]);
    const [filter, setFilter] = useState(false)
    const [loader, setLoader] = useState(false)
    const [limit, setLimit] = useState(216);
    let history = useHistory();

    function closeMenu() {
        setFilter(!filter)
    }

    function openSearchDialogHandler() {
        props.searchDataDialogToggle(!props.searchDialogStatus)
    }
    // var t1 = 1;
    const [t1, setT1] = useState(1);

    const handleScrollChild = () => {
        let cardWraaper = document.querySelector('#dashCard')

        let clientHeight = cardWraaper.clientHeight;
        let scrollTop = cardWraaper.scrollTop;
        var windowBottomHeight = cardWraaper.scrollHeight;
        let h = clientHeight * 95 / 100;
        let loaderH = clientHeight * 80 / 100;
        let tempHeight = 0
        let loaderTempHeight = 0
        if (clientHeight < 500) {
            tempHeight = h * 4
            loaderTempHeight = loaderH * 4
        } else {
            tempHeight = h * 3
            loaderTempHeight = loaderH * 3
        }
        let userScrollHeight = clientHeight + scrollTop;
        let userScrollHeightRatio = userScrollHeight + tempHeight;
        let loaderUserScrollHeightRatio = userScrollHeight + loaderTempHeight;

        if (userScrollHeightRatio > windowBottomHeight * .3) {
            console.debug("Vk:t before ", t1);

            if (t1 == 1) {
                console.debug("Vk:t before ", t1);
                // t1 = 0;
                console.debug("Vk:candidateTotalCount ", props.candidateTotalCount);
                console.debug("Vk:candidateTotalList ", props);
                if (props.candidateTotalCount < props.candidateTotalList) {
                    // t1 = 0;
                    setT1(0);
                    console.debug("Vk:t after ", t1);
                    // if (userScrollHeight + 80 >= windowBottomHeight) {
                    //     props.setOpenBackdrop(true)
                    // }
                    // t1 = 0;
                    let abc = onhandleLoadmore().then(d => {
                        props.setClearExperienceLoader("loader")
                        // t1 = 1;
                        setT1(1);
                    });
                }

            }
        }
    };

    var counter = 2
    async function onhandleLoadmore() {
        let formData = new FormData();
        if (props.searchedSideSkills != undefined) {
            var dataVal = [...props.searchedSideSkills]
        }
        console.debug(limit)
        if (props.candidateDataLimit <= props.candidateTotalList) {
            var count = counter++;
            var value = props.candidateDataLimit * count
            setLimit(value)
            if (dataVal) {
                let filteredSkills = dataVal.find(d => {
                    if (d != props.searchRole) {
                        dataVal = [...props.searchedSideSkills, props.searchRole]
                    }
                })
            }
            console.debug("searchSkills",props.searchedSideSkills)
            if (!props.releventTitleStatus) {
                if (props.searchRole) {
                    formData.append('job', props.searchRole);
                }
                if (props.searchedSideSkills != undefined) {
                    if (props.searchedSideSkills.length) {
                        props.searchedSideSkills.forEach((val, i) => {
                            formData.append(`skills[${i}]`, val);
                        });
                    }
                }
            } else {
                if (dataVal) {
                    if (dataVal.length) {
                        dataVal.forEach((val, i) => {
                            formData.append(`skills[${i}]`, val);
                        });
                    }
                }
            }
            if (props.searchZipCode || props.searchRadius) {
                formData.append('zipCode', props.searchZipCode);
                formData.append('radius', props.searchRadius);
            }
            formData.append('limit', value);

            if (props.candidateExperienceLevel.length) {
                props.candidateExperienceLevel.forEach((val, i) => {
                    formData.append(`experienceId[${i}]`, val.id);
                });
            }
            props.setCandidateLimit(value)
            // if (props.releventTitleStatus) {
            //     releventVersion(formData)
            // } else {
            await jobFilter(formData).then(function (response) {
                // await jobFilter(formData).then(function (response) {
                if (Array.isArray(response.data.data)) {
                    let candidate = response.data
                    console.debug("dashboardcard : loadmore data : candidate", candidate)
                    props.setOpenBackdrop(false)
                    props.setClearExperienceLoader("")
                    props.setCandidateCount(candidate.totalList);
                    if (props.experinceStatus == "Experience- high to low") {
                        var hightTolow = candidate.data.sort((a, b) => {
                            var c = a.workExperience;
                            var d = b.workExperience;
                            return d - c;

                        })
                        props.setCandidateSearchedData([...hightTolow])
                    }
                    if (props.experinceStatus == "Experience- low to high") {
                        var lowTohigh = candidate.data.sort((a, b) => {
                            var c = a.workExperience;
                            var d = b.workExperience;
                            return c - d;

                        })
                        props.setCandidateSearchedData([...lowTohigh])
                    }
                    if (props.experinceStatus == "Relevance") {
                        props.setCandiadteMaster(candidate.data)
                        props.setCandidateSearchedData(candidate.data);
                    }
                    // props.setCandidateLimit(candidate.totalList)


                }
            }).catch(function (error) {
                console.log(error);
                console.log(JSON.stringify(error));
                console.log(error.response);
                props.networkErrorDialogToggle(!props.dialogStatus);
            });
            // }
        }

    }

    // ----------------- version-6 and count v-4 function----------------

    function releventVersion(data) {
        jobFilterV1(data).then(function (response) {
            if (Array.isArray(response.data.data)) {
                let candidate = response.data
                props.setReleventTitleStatus(true)
                props.setOpenBackdrop(false)
                props.setCandidateCount(candidate.totalList);

                if (props.experinceStatus == "Experience- high to low") {
                    var hightTolow = candidate.data.sort((a, b) => {
                        var c = a.workExperience;
                        var d = b.workExperience;
                        return d - c;

                    })
                    props.setCandidateSearchedData([...hightTolow])
                }
                if (props.experinceStatus == "Experience- low to high") {
                    var lowTohigh = candidate.data.sort((a, b) => {
                        var c = a.workExperience;
                        var d = b.workExperience;
                        return c - d;

                    })
                    props.setCandidateSearchedData([...lowTohigh])
                }
                if (props.experinceStatus == "Relevance") {
                    props.setCandiadteMaster(candidate.data)
                    props.setCandidateSearchedData(candidate.data);
                }
                // props.setClearFilter("")

            }
        })

    }

    function candidateSkillInfo() {
        let formData = new FormData();
        console.debug("response candidate seelcted")
        props.candidateSelectedData.forEach((val, i) => {
            console.debug(`candidateId[${i}]`, val.refId)
            formData.append(`candidateId[${i}]`, val.refId);
        });
        getCandidate(formData).then(function (response) {
            // if (Array.isArray(response.data.data)) {
            let candidate = response.data
            console.debug("response candidate seelcted", candidate)
            props.fetchSelectedCandidateData(candidate)
            history.push("/candidate-info");

            // }
        })
        // getCandidate
        // props.fetchSelectedCandidateData()
        // props.setSkillInfo()
    }

    return (
        <div className="Container">
            <FilteredByDialog />
            <div>
                <div className="border pl-4 pr-4">
                    <span onClick={closeMenu}
                        className="font-weight-bold small d-sm-inline-block d-lg-none
                         d-md-inline-block d-xl-none d-inline-block ">
                        Modify Search
                    </span>
                    <span onClick={openSearchDialogHandler}
                        className="font-weight-bold small float-right d-sm-inline-block 
                    d-lg-none d-md-inline-block d-xl-none d-inline-block">
                        <img src="/img/Vector.png" className=" ml-2" />   Filter By
                    </span>
                </div>
                <div className="d-none d-sm-none d-lg-block d-md-none ">
                    <DashboardSearch setOpenBackdrop={props.setOpenBackdrop} />
                </div>
                {filter ? <SearchDialog /> : null}

                {/* ____________________ SIDEBAR __________________________________ */}
                <div className="row p-0 m-0">
                    <div className="d-none d-sm-none d-lg-block d-md-none sidebar col-md-12 col-sm-12 col-lg-2 col-xl-2 pl-4 pr-4 pt-4 overflow-auto overflowx-hidden">

                        <SideBar experience={experience} closeMenu={closeMenu} filter={filter} setOpenBackdrop={props.setOpenBackdrop} />
                    </div>
                    <div id="dashCard" onScroll={handleScrollChild} className="card-container d-inline-block col-md-12 col-sm-12 col-lg-10 col-xl-10 pb-4 height67 overflow-auto overflowx-hidden" >
                        {props.candidateSelectedData.length > 0 ? <div className="next-btn" onClick={candidateSkillInfo}>
                            <span className="position-absolute countCandidate">{props.candidateSelectedData.length}/5</span>
                            {/* <Link to="/candidate-info" ><img src="./img/next.svg" /></Link> */}
                            <img src="./img/next.svg" />
                        </div> : ""}
                        {/* <Typography className="mt-4 d-sm-none d-lg-block d-md-none d-xl-block d-none" ><span>{props.candidateTotalCount} of {props.candidateTotalList} Results </span>
                            {"for " + props.candidateRole}
                        </Typography> */}
                        <DashboardCards loader={loader} />
                    </div>
                </div>
            </div>


        </div>
    );
}
const mapStateToProps = state => {
    return {
        candidateData: state.candidate.candidateList ? state.candidate.candidateList : [],
        candidateTotalCount: state.candidate.candidateCount,
        candidateTotalList: state.candidate.candidateTotal,
        candidateRole: state.candidateSearch.searchRole,
        candidateSelectedData: state.candidateSelectedId.selectedFile,
        candidateDataLimit: state.candidate.candidateDataLimit,
        searchRole: state.candidateSearch.searchRole,
        searchCityName: state.candidateSearch.searchCityName,
        searchJob: state.candidateSearch.searchJob,
        searchSkills: state.candidateSearch.searchSkills,
        searchZipCode: state.candidateSearch.searchZipCode,
        searchRadius: state.candidateSearch.searchRadius,
        clearLoaderFilter: state.candidateSearch.clearLoaderFilter,
        masterCandidateData: state.candidate.candidateMaster,
        searchStatusTrigger: state.candidateSearch.searchStatusTrigger,
        candidateSelectedData: state.candidateSelectedId.selectedFile,
        candidateExperienceLevel: state.candidateSearch.candidateExperienceLevel,
        releventTitleStatus: state.candidate.releventTitleStatus,
        experinceStatus: state.candidate.experinceStatus,
        searchDialogStatus: state.searchDataDialog.searchDialogStatus,
        searchedSideSkills: state.candidateSearch.searchedSideSkills,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCandidateSearchedData: (jobId) => dispatch(setCandidateSearchedData(jobId)),
        setCandiadteSelectedId: (jobId) => dispatch(setCandiadteSelectedId(jobId)),
        setCandidateCount: (jobId) => dispatch(setCandidateCount(jobId)),
        setCandidateLimit: (jobId) => dispatch(setCandidateLimit(jobId)),
        setCandiadteMaster: (jobId) => dispatch(setCandiadteMaster(jobId)),
        setClearExperienceLoader: (jobId) => dispatch(setClearExperienceLoader(jobId)),
        setSearchStatusTrigger: (jobId) => dispatch(setSearchStatusTrigger(jobId)),
        setSkillInfo: (jobId) => dispatch(setSkillInfo(jobId)),
        fetchSelectedCandidateData: (jobId) => dispatch(fetchSelectedCandidateData(jobId)),

        networkErrorDialogToggle: (toggleStatus) => dispatch(networkErrorDialogToggle(toggleStatus)),
        setReleventTitleStatus: (data) => dispatch(setReleventTitleStatus(data)),
        searchDataDialogToggle: (jobId) => dispatch(searchDataDialogToggle(jobId)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

import React, { useState, useEffect } from "react";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getJobList, jobFilter, totalCount, getSkillsList, getJobListSuggestion, getJobSkillsSuggestion, getSkillsSuggestion } from "../_service/jobListing";
import { useHistory } from "react-router-dom";
import {
    setCandidateSearchedData, networkErrorDialogToggle, setSearchRole, setSearchCity,
    setSearchJob, setSearchSkills, setSearchZipCode, setCandidateTotalSkills,
    setSearchRadius, setCandidateCount, setCandidateTotal, setCandiadteMaster,
    setCandidateTotalSkillSuggestionMaster, setCandidateCardSkills, setSkillSuggestionsList,
    setSelectedSkillSuggestionArrayObject, setSelectedSkills, setRemoteStatus
    //setCandidateTotalCountData
} from "../redux";
import { connect } from "react-redux";
import './mediaquery.css'
import TextField from '@material-ui/core/TextField';
import Header from './Header/header';
import Headerv2 from './Header/Headerv2';
import DrawerSideBar from "./Header/drawerSidebar";
import Slider from 'react-rangeslider'
import { TransitionGroup } from 'react-transition-group';

function CandidateSearch(props) {
    const [jobPosition, setJobPosition] = useState([])
    const [zipCode, setZipCode] = useState("")
    const [zipValue, setZipValue] = useState(false);
    const [skillSet, setSkillSet] = useState([]);
    const [skill, setSkill] = useState([]);
    const [cityId, setCityId] = useState();
    const [skillValue, setSkillValue] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [rolerr, setRoleErr] = useState("");
    const [role, setRole] = useState("");
    const [zipCoderr, setZipCodeErr] = useState("");
    const [dataErr, setDataErr] = useState("");
    const [radius, setRadius] = useState(20);


    const [skillSuggestion, setSkillSuggestion] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [selectedSkillSuggestionArrayObj, setSelectedSkillSuggestionArrayObj] = useState([]);
    let history = useHistory();

    const myRef = React.createRef();
    const [slideDrawer, setSlideDrawer] = useState(false)
    useEffect(() => {
        function handleClickOutside(event) {
            if (myRef.current && !myRef.current.contains(event.target)) {
                setSlideDrawer(false);
            } else {
                //   setSlideDrawer(true);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myRef]);
    function drawerOpen() {
        setSlideDrawer(true)
    }


    function onSkillChangeHandlerV1(e, value, reason) {
        if (value.length > selectedSkill.length) {
            let addedSkill = value.filter(item => selectedSkill.indexOf(item) == -1);
            setSelectedSkillSuggestionArrayObj([{ "skill": addedSkill[0], "suggestion": [...skillSuggestion] }, ...selectedSkillSuggestionArrayObj])
        }
        // to remove selectedSkillSuggestionArrayObj
        if (value.length < selectedSkill.length) {
            let toRemoveSkill = selectedSkill.filter(item => value.indexOf(item) == -1);
            let updateArray = selectedSkillSuggestionArrayObj.filter(el => el.skill !== toRemoveSkill[0]);
            setSelectedSkillSuggestionArrayObj([...updateArray])
        }
        setSelectedSkill([...value])
    }

    function onTypeHandlerV1(e) {

        if (e.target.name === "cityName") {
            props.setStatus(true)
            setCityId(e.target.value);
            setDataErr("")
        }
        if (e.target.name === "job") {
            props.setStatus(true)
            setDataErr("")

            setRole(e.target.value);
        }
        if (e.target.name === "skills") {
            setDataErr("")
            props.setStatus(true)

            if (e.target.value) {
                console.debug(e.target.value, "target value")

                fetchSetSkillOption(e.target.value).then((r) => {
                    if (r && r.length) {
                        // setJobPosition([...r]);
                    }
                });
            }
        }
        if (e.target.name === "role") {
            console.debug("CandidateSearch : onTypeHandlerV1 : selected Role ", e.target.value)
            props.setStatus(true)
            setRoleErr("")
            if (e.target.value) {
                fetchSetRoleOption(e.target.value).then((r) => {
                    if (r && r.length) {
                        console.debug("CandidateSearch : onTypeHandlerV1 : Test Options  length", jobPosition.length)
                        console.debug("CandidateSearch : onTypeHandlerV1 : Test Response  length", r)
                        setJobPosition([...r]);
                    }
                });
            }
        }
    }

    function filterOptionsHandler(optionsd, state) {
        console.debug("CustomAutoComplete : CandidateSearch : filterOptionsHandler :filterOptions : options", jobPosition)
        console.debug("CustomAutoComplete : CandidateSearch : filterOptionsHandler :filterOptions : optionsd", optionsd)
        return optionsd;
    }

    async function fetchSetSkillOption(value) {
        const res = await getSkillsSuggestion(value, "skill").then(function (response) {
            let data = response.data
            console.debug("Candidate Search : onChangeSkillsTextHandler : skill Api Response", data.results)
            if (data) {
                setSkillSuggestion(data.results)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
    }


    async function fetchSetRoleOption(value) {
        const res = await getSkillsSuggestion(value, "jobtitle").then(function (response) {
            console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options ", response.data)
            let data = response.data
            if (data) {
                let results = data.results;
                // let d = data.results[0].aliases;
                // let t = [];
                let tLabel = [];
                let tempResult = results.map(v => {
                    // let d = v.label;
                    let d = v.alias ? v.alias : v.label;
                    if (d) {
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases FormaData Value ", value)
                        //     // console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases ",d)
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases length", d.length)
                        //     let labelArray = d.map(r => r.alias)
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray", labelArray)
                        //     if (labelArray.length > 1) {
                        tLabel = [...tLabel, d];
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray if Block", labelArray)
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases tLabel if Block", tLabel.length)
                        //         return tLabel;
                        //     }
                        //     else {
                        //         tLabel = [...tLabel];
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray Else Block", labelArray.length)
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases tLabel Else Block", tLabel)
                        //         return tLabel;
                        //     }
                    }
                })
                return tLabel;

            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
        return res;
    }
    // ------------ ZIPCODE SET HANDLER START ------------------

    function onChangeZipHandler(e) {
        const re = /[0-9]+/g;
        if (e.target.name === "zipCode") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setZipCode(e.target.value);
                setZipCodeErr("")
                props.setStatus(true)
                setCityId("")
            } else {
                setZipCodeErr(<div className="form-group m-0 ml-2 mt-2">
                    <span className="error-msg">Enter only number</span>
                </div>)
            }
        }

    }
    // ------------ ZIPCODE SET HANDLER END ------------------

    // ------------ DONTKNOW ZIP HANDLER START ------------------

    function oncheckhandler(e) {
        if (e.target.name == "checkBoxZip") {
            setZipValue(e.target.checked)
            props.setRemoteStatus(e.target.checked)
            setZipCodeErr("")
            if (e.target.checked) {
                setZipCode("")
            } else {
                setCityId("")
            }
        }

    }
    // ------------ DONTKNOW ZIP HANDLER END ------------------

    // _____________________ JOBROLE HANDLER START_______________________

    function onChangeRoleTextHandler(e, value) {
        e.preventDefault();
        // var element = document.getElementsByClassName("MuiAutocomplete-popper");
        // console.debug("elementtttttttttt")
        // element.classList.add("mystyle");
        console.debug("CandidateSearch : onChangeRoleTextHandler : Selected Role e.target.value ", e.target.value)
        console.debug("CandidateSearch : onChangeRoleTextHandler : Selected Role value ", value)
        setRole(value);
    }
    // _________________ Radius handler START___________________

    function handleChangeHorizontal(value) {
        setRadius(value)
    }

    // ______________________ SEARCH CANDIDATE DATA (SUBMIT FUNCTION)  _______________________

    function onSubmit(e) {
        let formData = {};
        if (jobPosition)
            if (role) {
                formData.job = role;
                setRoleErr("")
            } else {
                setRoleErr(<div className="form-group">
                    <span className="text-danger ml-3">Select role from suggestion </span>
                </div>)
            }
        if (!zipValue) {
            if (zipCode) {
                formData.zipCode = zipCode;
                formData.radius = radius == 0 ? 1 : radius;
                setZipCodeErr("")
            }
        }
        formData.limit = 216;
        if (selectedSkill.length) {
            formData.skills = selectedSkill;
        }

        if (selectedSkillSuggestionArrayObj) {
            var myArray = []
            props.setSelectedSkillSuggestionArrayObject([...selectedSkillSuggestionArrayObj])
            selectedSkillSuggestionArrayObj.map(function (d) {
                d.suggestion.map(function (v) {
                    myArray.push(v)
                    props.setSkillSuggestionsList(myArray.slice(0, 10))
                    props.setCandidateTotalSkillSuggestionMaster(myArray)
                })
            });
        }

        if (role && (zipCode || zipValue)) {
            setSpinner(true)
            totalCountFunction(formData)

            jobFilter(formData).then(function (response) {
                if (Array.isArray(response.data.data)) {
                    let candidate = response.data
                    if (candidate.data.length) {
                        props.setCandidateSearchedData(candidate.data);
                        props.setCandiadteMaster(candidate.data)
                        props.setCandidateCount(candidate.totalList);
                        props.setSearchRole(candidate.job);
                        props.setSearchSkills(candidate.skills);
                        props.setSearchJob(candidate.job);
                        props.setSelectedSkills(candidate.skills)
                        props.setSearchZipCode(candidate.zipCode ? candidate.zipCode : null);
                        props.setSearchRadius(candidate.radius == 1 ? 0 : candidate.radius);
                        setSpinner(false)
                        history.push("/dashboard");
                    } else {
                        // setDataErr(<div className="form-group m-0 ml-2 mt-2">
                        //     <span className="error-msg">No data found</span>
                        // </div>)
                        // setSpinner(false)
                        props.setSearchRole(role);
                        props.setSearchZipCode(zipCode ? zipCode : "");
                        props.setSearchSkills(selectedSkill);
                        props.setSearchRadius(radius ? radius : "");
                        history.push("/dashboard");

                    }
                    localStorage.setItem('state', JSON.stringify(candidate))
                }
                else {
                    history.push("/dashboard");

                    // setDataErr(<div className="form-group m-0 ml-2 mt-2">
                    //     <span className="error-msg">No data found</span>
                    // </div>)
                    // setSpinner(false)
                }
            }).catch(function (error) {
                console.log(error);
                console.log(JSON.stringify(error));
                console.log(error.response);
                props.networkErrorDialogToggle(!props.dialogStatus);
            });
        }
    }

    function totalCountFunction(data) {

        totalCount(data).then(function (response) {
            let candidate = response.data
            if (candidate) {
                console.debug("candidate data check on response total", candidate)
                props.setCandidateTotal(candidate.total);
            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
    }

    return (
        <div>
            {slideDrawer && <DrawerSideBar myRef={myRef} />}
            {/* <Header drawerOpen={drawerOpen} /> */}
            <Headerv2 drawerOpen={drawerOpen} />
            <br />
            <br />
            <br />
            <br />
            <div className="row col-md-12 m-0 condi-container">
                <div className="col-lg-6 col-md-12 col-sm-12 left-side-content m-auto 
                pl-0 pl-sm-0 pl-md-5 pl-lg-0 p-0 pt-0 pt-lg-0 pt-md-5 pt-sm-0">
                    <div className="row col-md-12 col-sm-12 col-lg-12 mt-3 mt-sm-3 mt-md-3 mt-lg-0 mt-xl-0 pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 pr-0">
                        <p className="font-weight-bolder text-left colorWhite fontSize25 mt-4">
                            <img alt="dotted-icon" src="./img/dots.svg" className="position-absolute d-sm-none d-md-block d-lg-block d-xl-block d-none " />
                            <span className="font-weight-light"> Your digital transformation.</span>
                            <span className="font-weight-light d-block">  Our resources in a <b>jiffy.</b> </span></p>
                        {/* <span className="font-weight-light"> Browse </span>Mastech Digital Talent</p> */}
                    </div>
                    <div className="row col-md-10 col-sm-10 col-lg-10 pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 pr-0">
                        <Autocomplete
                            loading
                            // open={true}
                            id="tags-filled"
                            name="jobId"
                            onChange={onChangeRoleTextHandler}
                            className="bg-white border-0  rounded-pill w-100 skillset crossPosition"
                            filterOptions={filterOptionsHandler}
                            autoComplete={true}
                            options={jobPosition}
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={onTypeHandlerV1}
                                    variant="filled"
                                    name="role"
                                    label="Role *"
                                />
                            )}
                        />

                        {rolerr}
                    </div>

                    <div className={"row col-md-10 col-sm-10 col-lg-10 pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 pr-0" + (rolerr ? " mt-0" : " mt-4")}>
                        <Autocomplete
                            loading
                            multiple
                            id="tags-filled"
                            name="skillsAutoComplete"
                            className="bg-white border-0  rounded-pill w-100 skillset"
                            options={skillSuggestion ? skillSuggestion.map((option) => option.alias ? option.alias : option.label) : []}
                            onChange={onSkillChangeHandlerV1}
                            freeSolo
                            limitTags={2}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    onChange={onTypeHandlerV1}
                                    name="skills"
                                    label="Skillset "
                                />
                            )}
                        />
                    </div>

                    <div className="row col-md-12 col-sm-12 col-lg-12 pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 mt-4 pr-0">
                        {!zipValue && <div className="col-md-6 col-sm-6 col-lg-6 pl-0 pr-0 col-6">
                            <TextField className="text-form zipCode mr-0" name="zipCode" onChange={onChangeZipHandler} id="filled-basic" label="Zip Code" variant="filled" />
                            {zipCoderr}
                        </div>}
                        <div className="col-md-6 col-sm-6 col-lg-6 pl-0 pr-0 col-6">
                            <input type="checkbox" className="checkbox-input ml-sm-1 ml-md-1 ml-lg-3 ml-xl-3 m-0 ml-1 mr-2 mt-3" name="checkBoxZip" onChange={oncheckhandler} />
                            <span className="m-auto ml-0 ml-lg-0 ml-md-0 zipcode-span">Remote Position</span>
                        </div>
                    </div>
                    {!zipValue ? <div className=" col-md-12 col-sm-12 col-lg-12 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 candi-button row mt-4">
                        <div className="col-md-5 col-lg-5 col-xl-5 col-sm-8 col-8 p-sm-2 p-2 p-lg-0 p-md-0 p-xl-0">
                            <Slider
                                min={0}
                                max={100}
                                value={radius}
                                step={5}
                                handleLabel={radius + "mi".toString()}
                                onChange={handleChangeHorizontal}
                            />
                        </div>

                        <div className="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 p-sm-2 p-2 p-lg-0 p-md-0 p-xl-0">
                            <span className="colorWhite d-inline-block ml-0 ml-lg-3 ml-md-3 ml-sm-0 ml-xl-3 mt-3">Distance</span>
                        </div>
                    </div> : ""}
                    <div className="row col-md-12 col-sm-12 col-lg-12  pl-0 pl-sm-0 pl-lg-5 pl-md-0 pl-xl-5 mt-3 ml-sm-0 ml-md-0 ml-lg-5 ml-xl-5 ml-0 mb-3">
                        <button className="search colorWhite" onClick={() => onSubmit()} >
                            Search
                            {!spinner ? <img src="/img/search.svg" className="ml-2" /> : <i className="fa fa-spinner fa-spin ml-2"></i>}
                        </button>
                        {dataErr}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 d-inline-block mb-3 ml-0 ml-lg-5 ml-md-0 ml-sm-0 ml-xl-5 mt-3 pl-0 pl-lg-5 pl-md-0 pl-sm-0 pl-xl-5 row">
                        <span className="d-inline-block">Note:</span>
                        <span className="d-inline ml-2 small">The results displayed are from our proprietary
                            "ready" database. The candidate profiles displayed are
                            only a subset of our overall talent pool.
                            Mastech Digital has access to and can source additional candidates
                            should the profile results not meet your specific needs.</span>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 right-side-content d-sm-none d-md-block d-lg-block d-xl-block d-none"><img src="./img/canditpool.svg" /></div>
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        candidateData: state.candidate.candidateList,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCandidateSearchedData: (jobId) => dispatch(setCandidateSearchedData(jobId)),
        setCandiadteMaster: (jobId) => dispatch(setCandiadteMaster(jobId)),
        setSearchRole: (data) => dispatch(setSearchRole(data)),
        setSearchCity: (data) => dispatch(setSearchCity(data)),
        setSearchJob: (data) => dispatch(setSearchJob(data)),
        setSearchSkills: (data) => dispatch(setSearchSkills(data)),
        setSearchZipCode: (data) => dispatch(setSearchZipCode(data)),
        setSearchRadius: (data) => dispatch(setSearchRadius(data)),
        setCandidateCount: (data) => dispatch(setCandidateCount(data)),
        setCandidateTotal: (data) => dispatch(setCandidateTotal(data)),
        setCandidateTotalSkills: (data) => dispatch(setCandidateTotalSkills(data)),
        setCandidateTotalSkillSuggestionMaster: (data) => dispatch(setCandidateTotalSkillSuggestionMaster(data)),
        setCandidateCardSkills: (data) => dispatch(setCandidateCardSkills(data)),
        setSkillSuggestionsList: (data) => dispatch(setSkillSuggestionsList(data)),
        setSelectedSkillSuggestionArrayObject: (data) => dispatch(setSelectedSkillSuggestionArrayObject(data)),
        setSelectedSkills: (jobId) => dispatch(setSelectedSkills(jobId)),
        setRemoteStatus: (jobId) => dispatch(setRemoteStatus(jobId)),

        networkErrorDialogToggle: (toggleStatus) => dispatch(networkErrorDialogToggle(toggleStatus)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateSearch)
import React, { useEffect, useState } from 'react';
import './App.css';
import PrivateRoute from './PrivateRoute'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CandidateInfo from './Staffing/candidateInfo';
import Dashboard from './Staffing/Dashboard/dashboard';
import CandidateSearch from './Staffing/candidateSearch';
import ThankYou from './Staffing/thankyou';
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import CandidateInquiry from './Staffing/candidateInquiry';

require('dotenv').config();
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
function App() {
  const [status, setStatus] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const classes = useStyles();

  // let status = localStorage.getItem("auth");
  useEffect(function (e) {
    window.onload = (e) => {
      console.log('page is fully loaded');
    };
  }, [])
  return (
    <>
      <Router>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          <Route exact path="/">
            <CandidateSearch setStatus={setStatus} />
          </Route>
          {/* <PrivateRoute exact path="/">
            <CandidateSearch />
          </PrivateRoute> */}
          <PrivateRoute exact path="/dashboard" loginStatus={status} >
            <Dashboard setOpenBackdrop={setOpenBackdrop} />
          </PrivateRoute>
          <PrivateRoute exact path="/candidate-info" loginStatus={status}>
            <CandidateInfo setStatus={setStatus} />
          </PrivateRoute>
          <PrivateRoute exact path="/candidate-inquiry" loginStatus={status}>
            <CandidateInquiry setStatus={setStatus} />
          </PrivateRoute>
          <PrivateRoute exact path="/thank-you" loginStatus={status}>
            <ThankYou />
          </PrivateRoute>
        </Switch>
      </Router>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import {
    setCandidateSearchedData, networkErrorDialogToggle, setSearchRole, setSearchCity,
    setSearchJob, setSearchSkills, setSearchZipCode, setCandidateTotalSkills, setSkillSuggestionsList,
    setSearchRadius, setCandidateCount, setCandidateTotal, setCandiadteMaster, setCandidateTotalSkillSuggestionMaster,
    setCandidateLimit, setClearFilter, setSearchStatusTrigger, setSelectedSkillSuggestionArrayObject, setSelectedSkills,
    setCandiadteSelectedId, setRemoteStatus
} from "../../redux";
import { getJobList, jobFilter, totalCount, getJobListSuggestion, getJobSkillsSuggestion, getSkillsSuggestion } from "../../_service/jobListing";
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Slider from 'react-rangeslider'
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
function DashboardSearch(props) {
    let history = useHistory();
    const [jobPosition, setJobPosition] = useState([])
    const [role, setRole] = useState(props.searchRole != undefined ? props.searchRole : "")
    const [radius, setRadius] = useState(props.searchRadius != undefined ? props.searchRadius : 20);
    const [zipCode, setZipCode] = useState(props.searchZipCode != undefined ? props.searchZipCode : "")
    const [city, setCity] = useState(props.searchCityName != undefined ? props.searchCityName : "");
    const [zipValue, setZipValue] = useState(props.searchZipCode ? false : true);
    const [experience, setExperience] = useState([]);
    const [skillValue, setSkillValue] = useState();
    const [loader, setLoader] = useState(false)
    const [spinner, setSpinner] = useState(false);
    const [limit, setLimit] = useState(216);
    const [skillSuggestion, setSkillSuggestion] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(props.searchSkills != undefined ? props.searchSkills : []);
    const [selectedSkillSuggestionArrayObj, setSelectedSkillSuggestionArrayObj] = useState([]);

    const [zipError, setZipError] = useState('')

    const toolTipContent = "If the profiles from the search result do not match your specific need, we can still help.  Click here."

    useEffect(function (e) {
        setRole(props.searchRole != undefined ? props.searchRole : "")
        setSelectedSkill(props.searchSkills ? props.searchSkills : [])
        setRadius(props.searchRadius != undefined ? props.searchRadius : 20)
        setCity(props.searchCityName != undefined ? props.searchCityName : "")
        setZipCode(props.searchZipCode != undefined ? props.searchZipCode : "")
    }, []);

    function onTypeHandlerV1(e) {

        if (e.target.name === "job") {
            props.setStatus(true)
            setRole(e.target.value);
        }
        if (e.target.name === "skills") {
            // if (e.target.value) {
            // fetchSetSkillOption(e.target.value);
            if (e.target.value) {
                console.debug(e.target.value, "target value")
                // fetchSetSkillOption(e.target.value);
                fetchSetSkillOption(e.target.value).then((r) => {
                    if (r && r.length) {
                        // console.debug("CandidateSearch : onTypeHandlerV1 : Options ",options)
                        // console.debug("CandidateSearch : onTypeHandlerV1 : Test Options  length",jobPosition.length)
                        // console.debug("CandidateSearch : onTypeHandlerV1 : Test Response  length",r.length)
                        // setJobPosition([...r]);
                    }
                });
            }
            // }
        }
        if (e.target.name === "role") {
            // if (e.target.value) {
            if (e.target.value) {
                fetchSetRoleOption(e.target.value).then((r) => {
                    if (r && r.length) {
                        // console.debug("CandidateSearch : onTypeHandlerV1 : Options ",options)
                        console.debug("CandidateSearch : onTypeHandlerV1 : Test Options  length", jobPosition.length)
                        console.debug("CandidateSearch : onTypeHandlerV1 : Test Response  length", r.length)
                        setJobPosition([...r]);
                    }
                });
            }
            // fetchSetRoleOption(e.target.value);
            // }
        }


    }
    function filterOptionsHandler(optionsd, state) {
        console.debug("CustomAutoComplete : CandidateSearch : filterOptionsHandler :filterOptions : options", jobPosition)
        console.debug("CustomAutoComplete : CandidateSearch : filterOptionsHandler :filterOptions : optionsd", optionsd)
        return optionsd;
    }
    async function fetchSetSkillOption(value) {
        const res = await getSkillsSuggestion(value, "skill").then(function (response) {

            // const res = await getJobSkillsSuggestion({ "skill": value }).then(function (response) {
            let data = response.data
            console.debug("Candidate Search : onChangeSkillsTextHandler : skill Api Response", data.results)
            if (data) {
                setSkillSuggestion(data.results)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
    }

    async function fetchSetRoleOption(value) {
        // const res = await getJobListSuggestion({ "title": value }).then(function (response) {

        const res = await getSkillsSuggestion(value, "jobtitle").then(function (response) {
            // console.debug("CandidateSearch : fetchSetRoleOption : getJobListSuggestion Sucess : ",response.data)
            console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options ", response.data)
            let data = response.data
            if (data) {
                // return data.results

                let results = data.results;
                // let d = data.results[0].aliases;
                // let t = [];
                let tLabel = [];
                let tempResult = results.map(v => {
                    let d = v.alias ? v.alias : v.label;
                    if (d) {
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases FormaData Value ", value)
                        //     // console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases ",d)
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases length", d.length)
                        //     let labelArray = d.map(r => r.alias)
                        //     console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray", labelArray)
                        //     if (labelArray.length > 1) {
                        tLabel = [...tLabel, d];
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray if Block", labelArray)
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases tLabel if Block", tLabel.length)
                        //         return tLabel;
                        //     }
                        //     else {
                        //         tLabel = [...tLabel];
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases labelArray Else Block", labelArray.length)
                        //         console.debug("CandidateSearch : onChangeRoleTextHandler : Role Options aliases tLabel Else Block", tLabel)
                        //         return tLabel;
                        //     }
                    }
                })
                return tLabel;

            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
        return res;
    }

    function onChangeRoleTextHandler(e, value) {
        e.preventDefault();
        console.debug("CandidateSearch : onChangeRoleTextHandler : Selected Role e.target.value ", e.target.value)
        console.debug("CandidateSearch : onChangeRoleTextHandler : Selected Role value ", value)
        setRole(value);
    }
    function onSkillChangeHandlerV1(e, value, reason) {
        if (value.length > selectedSkill.length) {
            let addedSkill = value.filter(item => selectedSkill.indexOf(item) == -1);
            props.setSelectedSkillSuggestionArrayObject([...props.selectedSkillSuggestionArrayObject, { "skill": addedSkill[0], "suggestion": [...skillSuggestion] }, ...selectedSkillSuggestionArrayObj])
            console.debug("dashboard search skill after add selectedSkillSuggestionArrayObj ", selectedSkillSuggestionArrayObj)

        }
        // to remove selectedSkillSuggestionArrayObj
        if (value.length < selectedSkill.length) {
            console.debug("dashboard search skill remove selectedSkill ", selectedSkill)
            console.debug("dashboard search skill after removed Skill  skillSuggestion value", value)

            let toRemoveSkill = selectedSkill.filter(item => value.indexOf(item) == -1);
            // let updateArray = selectedSkillSuggestionArrayObj.filter(el => el.skill !== toRemoveSkill[0]);
            if (!value.length) {
                var selectedSkillSuggestionArrayObject = props.selectedSkillSuggestionArrayObject.filter(el => {
                    selectedSkill.filter(item => {
                        if (el.skill !== item) {
                            return el
                        }
                    })

                }
                );
            } else {
                var selectedSkillSuggestionArrayObject = props.selectedSkillSuggestionArrayObject.filter(el => el.skill !== toRemoveSkill[0]);

            }
            // setSelectedSkillSuggestionArrayObj([...updateArray])
            props.setSelectedSkillSuggestionArrayObject([...selectedSkillSuggestionArrayObject])
            console.debug("dashboard search skill after removed Skill  skillSuggestion selectedSkillSuggestionArrayObject", selectedSkillSuggestionArrayObject)
            // console.debug("dashboard search skill after removed Skill  skillSuggestion updateArray", updateArray)
        }
        setSelectedSkill([...value])
    }

    function onChangeTextHandler(e, value) {
        e.preventDefault();

        if (e.target.name === "cityName") {
            setCity(e.target.value);
        }
    }

    function oncheckhandler(e) {
        console.debug(e.target.checked)


        if (e.target.name == "checkBoxZip") {
            props.setRemoteStatus(e.target.checked)
            setZipValue(!zipValue)
        }
    }

    function onChangeZipHandler(e) {
        const re = /[0-9]+/g;
        if (e.target.name === "zipCode") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setZipCode(e.target.value);
                setZipError('')
            } else {
                setZipError(<div className="form-group m-0 ml-2 mt-2">
                    <span className="error-msg">Enter only number</span>
                </div>)
            }
        }
    }

    function handleChangeHorizontal(value) {
        setRadius(value)
    }

    function onSubmit(e) {
        let formData = {};
        if (role) {
            formData.job = role;
        }
        if (zipCode && !zipValue) {
            formData.zipCode = zipCode;
            formData.radius = radius == 0 ? 1 : radius;
        }
        // if (city && zipValue) {
        //     formData.cityName = city
        // }
        if (selectedSkill.length) {
            formData.skills = selectedSkill;
            // formData.skills = { jobTitle: 'java developer', skill: selectedSkill }
        }
        if (limit) {
            formData.limit = limit;
            props.setCandidateLimit(216)
        }

        if (selectedSkillSuggestionArrayObj) {
            var myArray = []
            let skillSuggestionProp = props.selectedSkillSuggestionArrayObject
            skillSuggestionProp.map(function (d) {
                console.debug("selectedSkillSuggestionArrayObj myArray d", d)
                d.suggestion.map(function (v) {
                    myArray.push(v)
                    console.debug(" selectedSkillSuggestionArrayObj myArray myarray", myArray)
                    props.setSkillSuggestionsList(myArray.slice(0, 10))
                    props.setCandidateTotalSkillSuggestionMaster(myArray)
                })
            });
        }

        if (role || selectedSkill.length) {
            setLoader(true)
            setSpinner(true)
            props.setOpenBackdrop(true)
            props.setSearchStatusTrigger(true)
            props.setCandiadteSelectedId([])
            jobFilter(formData).then(function (response) {
                if (Array.isArray(response.data.data)) {
                    if (document.querySelector('#dashCard').scrollTop > 0) {
                        document.querySelector('#dashCard').scrollTop = 0
                    }
                    let candidate = response.data
                    if (candidate.data.length) {
                        candidate.role = role;
                        props.setCandidateSearchedData(candidate.data);
                        props.setCandiadteMaster(candidate.data)
                        props.setSearchCity(candidate.cityName);
                        props.setSearchSkills(candidate.skills);
                        props.setSearchJob(candidate.job);
                        props.setSelectedSkills(candidate.skills);
                        props.setSearchRole(candidate.role);
                        props.setSearchZipCode(candidate.zipCode);
                        props.setSearchRadius(candidate.radius == 1 ? 0 : candidate.radius);
                        props.setCandidateCount(candidate.totalList);
                        props.setClearFilter("filter")
                        props.setOpenBackdrop(false)
                        setSpinner(false)
                        setExperience([])
                    } else {
                        props.setCandidateSearchedData([]);
                        setSpinner(false)
                        props.setOpenBackdrop(false)

                    }
                }
            }).catch(function (error) {
                console.log(error);
                console.log(JSON.stringify(error));
                console.log(error.response);
                props.networkErrorDialogToggle(!props.dialogStatus);

            });

            totalCountFunction(formData)

        }
    }

    function totalCountFunction(data) {

        totalCount(data).then(function (response) {
            let candidate = response.data
            console.debug("dashboard search : candidate:", candidate)
            props.setCandidateTotal(candidate.total);

            setExperience([])
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);

        });
    }

    function onSubmitInquiry() {
        history.push("/candidate-inquiry");
        console.debug("dashboard search")
    }

    return (
        <div className="col-md-12 row m-0 dashboard-container ">
            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 dashboard-head p-0 pl-0 pl-sm-0 pl-md-5 pl-lg-0 mt-0 mt-sm-0 mt-lg-0 mt-md-2 row">
                <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12 pr-0 row m-0 pl-3 pl-sm-3 pl-md-3 pl-lg-0">
                    <div className="col-md-8 col-lg-6 col-xl-6 col-sm-12 col-12 pl-0 pl-sm-0 pl-lg-3 pl-md-0 pr-3 pr-sm-3 pr-md-2 pr-lg-0">
                        <Autocomplete
                            loading
                            id="tags-filled"
                            name="jobId"
                            onChange={onChangeRoleTextHandler}
                            className="bg-white border-0  rounded-pill w-100 skillset paddingTopRole transitionScale"
                            filterOptions={filterOptionsHandler}
                            disableCloseOnSelect
                            value={role}
                            options={jobPosition}
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={onTypeHandlerV1}
                                    variant="filled"
                                    name="role"
                                    label="Role *"
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-6 col-sm-12 col-12 pl-0 pl-sm-0 pl-lg-3 pl-md-0 pr-3 
                    pr-sm-3 pr-md-3 pr-lg-0 mt-2 mt-sm-2 mt-md-2 mt-lg-0">
                        {console.debug("selectedSkill", selectedSkill)}
                        <Autocomplete
                            loading
                            multiple
                            id="tags-filled"
                            className="bg-white skillSetOption border-0 select-Position rounded-pill w-100 skillset transitionScale"
                            options={skillSuggestion ? skillSuggestion.map((option) => option.alias ? option.alias : option.label) : []}
                            value={selectedSkill}
                            onChange={onSkillChangeHandlerV1}
                            freeSolo
                            limitTags={1}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    onChange={onTypeHandlerV1}
                                    name="skills"
                                    label="Skillset"
                                />
                            )}
                        />

                    </div>

                </div>
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-8 col-12 row m-0 pr-0 nowWrap">
                    {!zipValue && <div className="col-md-8 col-lg-2 col-xl-2 col-sm-6 col-6 mt-2 mt-sm-2 mt-md-3 mt-lg-0 mt-xl-0 pr-lg-0 pr-sm-2 pr-2 pr-md-0 pr-xl-0 pl-0">
                        <TextField className="text-form zipCode zip mr-0 transitionScale" value={zipCode} name="zipCode" onChange={onChangeZipHandler} id="filled-basic" label="Zip Code" variant="filled" />
                        {zipError}

                    </div>
                    }

                    <div className="col-md-4 col-lg-3 col-xl-2 col-sm-3 col-3 mt-2 pt-0">
                        <div className=" checkb0x-span mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-2">
                            <input type="checkbox" checked={zipValue} name="checkBoxZip" onChange={oncheckhandler} />
                            <span className="position-absolute white-color">Remote Position</span>

                        </div>
                    </div>
                    {!zipValue ? <div className=" col-md-8 col-lg-4 col-xl-3 col-sm-7 col-7
                    mt-4 mt-sm-4 mt-md-4 mt-lg-0 p-sm-2 p-2 p-lg-0 p-md-0 p-xl-0">
                        <Slider
                            min={0}
                            max={100}
                            value={radius}
                            step={5}
                            handleLabel={radius + "mi"}
                            onChange={handleChangeHorizontal}
                        />
                    </div> : ""}
                    {!zipValue ? <div className="col-md-2 col-lg-1 col-xl-1 col-sm-2 col-2 p-sm-0 p-0 p-lg-0 p-md-0 p-xl-0">
                        <span className="colorWhite d-inline-block ml-0 ml-lg-3 ml-md-3 ml-sm-0 ml-xl-3 mt-5 mt-sm-5 mt-md-4 mt-lg-3">Distance</span>
                    </div> : ""}
                    <div className="col-md-12 col-sm-4 col-lg-4 col-xl-4 pl-sm-5 pl-md-0 pl-lg-3 pl-xl-3 mt-0 mt-sm-0 mt-md-4 mt-lg-0 pr-0">
                        <button className="colorWhite mt-2 form-control-sm search-btn" onClick={() => onSubmit()}>Search
                            {!spinner ? <img src="/img/search.svg" className="ml-2" /> : <i className="fa fa-spinner fa-spin ml-2"></i>}
                        </button>

                    </div>

                </div>
                <Tooltip title={toolTipContent} arrow>
                    <button className="bg-transparent border ml-3 pl-3 position-absolute pr-3 rounded-pill small text-white" style={{height:"35px",bottom:"3px",right:"15px"}}
                     onClick={onSubmitInquiry}>
                        No matching profiles?
                    </button>
                </Tooltip>
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        searchRole: state.candidateSearch.searchRole,
        searchCityName: state.candidateSearch.searchCityName,
        searchJob: state.candidateSearch.searchJob,
        searchSkills: state.candidateSearch.searchSkills,
        searchZipCode: state.candidateSearch.searchZipCode,
        searchRadius: state.candidateSearch.searchRadius,
        candidateDataLimit: state.candidate.candidateDataLimit,
        candidateTotalSkillSuggestionMaster: state.candidate.candidateTotalSkillSuggestionMaster,
        candidateSkillsSuggestion: state.candidate.candidateSkillsSuggestion,
        selectedSkillSuggestionArrayObject: state.candidate.selectedSkillSuggestionArrayObject,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCandidateSearchedData: (jobId) => dispatch(setCandidateSearchedData(jobId)),
        setCandiadteMaster: (jobId) => dispatch(setCandiadteMaster(jobId)),
        setSearchRole: (data) => dispatch(setSearchRole(data)),
        setSearchCity: (data) => dispatch(setSearchCity(data)),
        setSearchJob: (data) => dispatch(setSearchJob(data)),
        setSearchSkills: (data) => dispatch(setSearchSkills(data)),
        setSearchZipCode: (data) => dispatch(setSearchZipCode(data)),
        setSearchRadius: (data) => dispatch(setSearchRadius(data)),
        setCandidateCount: (data) => dispatch(setCandidateCount(data)),
        setCandidateTotal: (data) => dispatch(setCandidateTotal(data)),
        setCandidateTotalSkills: (data) => dispatch(setCandidateTotalSkills(data)),
        setSkillSuggestionsList: (data) => dispatch(setSkillSuggestionsList(data)),
        setCandidateTotalSkillSuggestionMaster: (data) => dispatch(setCandidateTotalSkillSuggestionMaster(data)),
        setCandidateLimit: (jobId) => dispatch(setCandidateLimit(jobId)),
        setClearFilter: (jobId) => dispatch(setClearFilter(jobId)),
        setSearchStatusTrigger: (jobId) => dispatch(setSearchStatusTrigger(jobId)),
        setSelectedSkillSuggestionArrayObject: (data) => dispatch(setSelectedSkillSuggestionArrayObject(data)),
        setSelectedSkills: (jobId) => dispatch(setSelectedSkills(jobId)),
        setCandiadteSelectedId: (jobId) => dispatch(setCandiadteSelectedId(jobId)),
        setRemoteStatus: (jobId) => dispatch(setRemoteStatus(jobId)),

        networkErrorDialogToggle: (toggleStatus) => dispatch(networkErrorDialogToggle(toggleStatus)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardSearch)
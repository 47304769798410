import {
  SET_CANDIDATE_SEARCHED_DATA_REQUEST,
  SET_CANDIDATE_SEARCHED_DATA_SUCCESS,
  SET_CANDIDATE_SEARCHED_DATA_FAILURE,
  SET_CANDIDATE_SEARCHED_DATA,
  SET_CANDIDATE_COUNT,
  SET_CANDIDATE_TOTAL,
  SET_CANDIDATE_MASTER,
  SET_CANDIDATE_TOTAL_SKILLS,
  SET_CANDIDATE_TOTAL_SKILLS_MASTER,
  SET_CANDIDATE_CARD_SKILLS,
  SET_SKILL_SUGGESTIONS_LIST,
  SET_CANDIDATE_LIMIT,
  SET_SELECTED_SKILL_SUGGESTION_OBJECT,
  SET_SELECTED_SKILLS,
  SET_SKILLS_INFO,
  SET_RELEVENT_TITLE_STATUS,
  SET_EXPERIENCE_STATUS,
  SET_REMOTE_STATUS
} from './candidateTypes'

const initialState = {
  candidateList: [],
  candidateDataLimit: 216,
  candidateMaster: [],
  candidateCount: null,
  candidateTotal: null,
  candidateTotalSkills: [],
  candidateTotalSkillSuggestionMaster: [],
  candidateCardSkills: [],
  candidateSkillsSuggestion: [],
  selectedSkillSuggestionArrayObject: [],
  selectedCandiSkill: [],
  skillsInfo: [],
  releventTitleStatus: false,
  experinceStatus: null,
  remoteStatus:null
};

function candidate(state = initialState, action) {
  switch (action.type) {
    // case SET_CANDIDATE_SEARCHED_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case SET_CANDIDATE_SEARCHED_DATA_SUCCESS:
    //   console.debug("candidate success data ", action.payload)
    //   return {
    //     loading: false,
    //     candidateList: action.payload.data,
    //     candidateMaster: action.payload.data,
    //     candidateCount: action.payload.totalList,
    //     selectedSkill:action.payload.skills,
    //     error: ''
    //   }
    //   break;

    // case SET_CANDIDATE_SEARCHED_DATA_FAILURE:
    //   return {
    //     loading: false,
    //     candidateList: [],
    //     error: action.payload
    //   }
    //   break;
    case SET_SKILLS_INFO:
      {
        return {
          ...state,
          skillsInfo: action.payload
        }
      }
      break;
    case SET_CANDIDATE_SEARCHED_DATA:
      {
        return {
          ...state,
          candidateList: action.payload
        }
      }
      break;
    case SET_CANDIDATE_LIMIT:
      {
        return {
          ...state,
          candidateDataLimit: action.payload
        }
      }
      break;
    case SET_SKILL_SUGGESTIONS_LIST:
      {
        return {
          ...state,
          candidateSkillsSuggestion: action.payload
        }
      }
      break;
    case SET_CANDIDATE_MASTER:
      {
        return {
          ...state,
          candidateMaster: action.payload
        }
      }
      break;
    case SET_CANDIDATE_COUNT:
      {
        return {
          ...state,
          candidateCount: action.payload
        }
      }
      break;
    case SET_CANDIDATE_TOTAL:
      {
        return {
          ...state,
          candidateTotal: action.payload
        }
      }
      break;
    case SET_CANDIDATE_TOTAL_SKILLS:
      {
        return {
          ...state,
          candidateTotalSkills: action.payload
        }
      }
      break;
    case SET_CANDIDATE_TOTAL_SKILLS_MASTER:
      {
        return {
          ...state,
          candidateTotalSkillSuggestionMaster: action.payload
        }
      }
      break;
    case SET_SELECTED_SKILL_SUGGESTION_OBJECT:
      {
        return {
          ...state,
          selectedSkillSuggestionArrayObject: action.payload
        }
      }
      break;
    case SET_CANDIDATE_CARD_SKILLS:
      {
        return {
          ...state,
          candidateCardSkills: action.payload
        }
      }
    case SET_SELECTED_SKILLS:
      {
        return {
          ...state,
          selectedCandiSkill: action.payload
        }
      }
    case SET_RELEVENT_TITLE_STATUS:
      {
        return {
          ...state,
          releventTitleStatus: action.payload
        }
      }
      break;
    case SET_EXPERIENCE_STATUS:
      {
        return {
          ...state,
          experinceStatus: action.payload
        }
      }
      break;
    case SET_REMOTE_STATUS:
      {
        return {
          ...state,
          remoteStatus: action.payload
        }
      }
      break;

    default:
      return state
  }
}

export default candidate;
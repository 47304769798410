import React, { useState, useEffect } from "react";
function DrawerSideBar(props) {


    const drawerSubmenuOpen = (e, id) => {
        if (e.target.dataset.target ) {
            let id = e.target.dataset.target.split("#")
            let element = document.getElementById(id[1])
            let menunav = document.getElementById("menu-nav-menu")
            
            if (element) {
                element.classList.add("mm-current","mm-opened")
                menunav.classList.add("mm-subopened")
            }
        }
    }

    const backSubmenu = (e, id) => {
        console.debug("vdbnmnxzm", e)
        if (e.target.dataset.target ) {
            let id = e.target.dataset.target.split("#")
            let element = document.getElementById(id[1])
            let menunav = document.getElementById("menu-nav-menu")
            if (element) {
                element.classList.remove("mm-current","mm-opened")
                menunav.classList.remove("mm-subopened")
            }
        }
    }

    return (
        // <div className="bg-light main position-absolute vh-100 w-75 top0 transition5" ref={props.myRef}>
        //     <div className="mm-opened mm-panel">
        //         <span className="w-100 d-block mt-4 text-center">Menu</span>
        //         <nav id="rtm-navigation" className="mt-5">
        //             <div id="nav" className="">
        //                 <ul id="menu-nav-menu" className="nav-menu rtm-menu d-flex flex-column">
        //                     {/* <li id="" className="pb-2 pt-2 pl-2"><a href="/" className="active"><span className="font-weight-normal">BROWSE CANDIDATE</span></a></li> */}
        //                     <li id="" className="pb-2 pt-2 pl-2"><a href="https://mastechinfotrellis.com/">
        //                         <span className="font-weight-normal">MASTECH INFOTRELLIS</span></a></li>
        //                     <li id="" className="pb-2 pt-2 pl-2">
        //                         <a href="https://www.mastechdigital.com/it-staffing-services/">
        //                             <span className="font-weight-normal">DIGITAL STAFFING</span></a></li>
        //                     <li id="" className="pb-2 pt-2 pl-2"><a href="https://www.mastechdigital.com/digital-learning-services/">
        //                         <span className="font-weight-normal">DIGITAL LEARNING</span></a></li>


        //                     {/* <li id="menu-item-11594" className="d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left"> */}
        //                     <li id="menu-item-11594" className="pb-2 pt-2 pl-2">
        //                         <a href="https://www.mastechdigital.com/company/"><span className="font-weight-normal">INVESTORS</span></a></li>
        //                     {/* <li id="menu-item-11594" className="d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left"> */}
        //                     <li id="menu-item-11594" className="pb-2 pt-2 pl-2">
        //                         <a href="https://www.mastechdigital.com/company/"><span className="font-weight-normal">NEWSROOM</span></a></li>
        //                     {/* <li id="menu-item-11594" className="d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left"> */}
        //                     <li id="menu-item-11594" className="pb-2 pt-2 pl-2">
        //                         <a href="https://www.mastechdigital.com/company/"><span className="font-weight-normal">CAREERS</span></a></li>
        //                     {/* <li id="menu-item-11594" className="d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left"> */}
        //                     <li id="menu-item-11594" className="pb-2 pt-2 pl-2">
        //                         <a href="https://www.mastechdigital.com/company/"><span className="font-weight-normal">BLOGS</span></a></li>


        //                     <li id="" className="pb-2 pt-2 pl-2"><a href="https://mastechinfotrellis.com/">
        //                         <span className="font-weight-normal"><img src="/img/plus.svg" /></span></a></li>

        //                 </ul>
        //             </div>
        //         </nav>
        //     </div>



        //     {/* --------------- drawer submenu list ------------------- */}

        //     <div class="mm-panel mm-hasnavbar mm-highest" id="mm-1"><div class="mm-navbar"><a class="mm-btn mm-prev" data-target="#menu-nav-menu"></a><a class="mm-title" href="#menu-nav-menu">DIGITAL STAFFING</a></div><ul class="sub-menu mm-listview mm-first mm-last">
        //         <li class="menu-item-type-post_type menu-item-object-page show-label">
        //             <a href="https://www.mastechdigital.com/offshore-staffing-services/">
        //                 <span class="main-nav-item-title main-title-with-desc">Offshore Staffing</span></a></li>
        //         <li class="menu-item-type-custom menu-item-object-custom show-label">
        //             <a href="https://www.mastechdigital.com/remote-staffing/">
        //                 <span class="main-nav-item-title main-title-with-desc">MAS-REMOTE</span></a></li>
        //         <li class="menu-item-type-custom menu-item-object-custom show-label">
        //             <a href="https://www.mastechdigital.com/it-staffing-services/">
        //                 <span class="main-nav-item-title main-title-with-desc">On-Premise Staffing</span></a></li>
        //         <li class="menu-item-type-custom menu-item-object-custom show-label">
        //             <a href="https://staffing.mastechdigital.com">
        //                 <span class="main-nav-item-title main-title-with-desc">Browse Candidates</span></a></li>
        //     </ul></div>

        // </div>

        // mm-offcanvas 
        <nav id="rnmm" class="mm-menu mm-current mm-opened" ref={props.myRef}>
            <div class="mm-panel mm-hasnavbar mm-opened mm-current" id="menu-nav-menu">
                <div class="mm-navbar"><a class="mm-title">Menu</a></div><ul class="mm-listview mm-first mm-last">
                    <li id="menu-item-11594" class="menu-item-type-custom menu-item-object-custom show-label rtm-menu-depth-0">
                        <a href="https://mastechinfotrellis.com/"><span class="main-nav-item-title main-title-with-desc">DIGITAL TRANSFORMATION</span></a></li>
                    <li id="menu-item-15188" class="menu-item-type-post_type menu-item-object-page menu-item-has-children show-label rtm-menu-depth-0"
                        onClick={(e) => drawerSubmenuOpen(e, "digital")}>
                        <a class="mm-next" data-target="#mm-1"></a><a href="https://www.mastechdigital.com/it-staffing-services/">
                            <span class="main-nav-item-title main-title-with-desc">DIGITAL STAFFING</span></a>

                    </li>
                    <li id="menu-item-11819" class="menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1638 current_page_item show-label rtm-menu-depth-0 mm-selected">
                        <a href="https://www.mastechdigital.com/digital-learning-services/"><span class="main-nav-item-title main-title-with-desc">DIGITAL LEARNING</span></a></li>
                    <li id="menu-item-3678" onClick={(e) => drawerSubmenuOpen(e, "company")}
                        class="menu-item-type-post_type menu-item-object-page menu-item-has-children show-label rtm-menu-depth-0">
                        <a class="mm-next"  data-target="#mm-2"></a><a href="https://www.mastechdigital.com/company/">
                            <span class="main-nav-item-title main-title-with-desc">COMPANY</span></a>

                    </li>
                    <li id="menu-item-2317" onClick={(e) => drawerSubmenuOpen(e, "investor")}
                        class="menu-item-type-custom menu-item-object-custom menu-item-has-children show-label rtm-menu-depth-0">
                        <a class="mm-next"  data-target="#mm-3"></a><a href="#"><span class="main-nav-item-title main-title-with-desc">INVESTORS</span></a>

                    </li>
                    <li id="menu-item-4576" class="menu-item-type-post_type menu-item-object-page show-label rtm-menu-depth-0">
                        <a href="https://www.mastechdigital.com/newsroom/"><span class="main-nav-item-title main-title-with-desc">NEWSROOM</span></a></li>
                    <li id="menu-item-3199" class="menu-item-type-post_type menu-item-object-page show-label rtm-menu-depth-0">
                        <a href="https://www.mastechdigital.com/careermain/"><span class="main-nav-item-title main-title-with-desc">CAREERS</span></a></li>
                    <li id="menu-item-4577" class="menu-item-type-post_type menu-item-object-page show-label rtm-menu-depth-0">
                        <a href="https://www.mastechdigital.com/blog/"><span class="main-nav-item-title main-title-with-desc">BLOGS</span></a></li>
                    <li id="menu-item-2523" onClick={(e) => drawerSubmenuOpen(e, "plus")}
                        class="plus-menu menu-item-type-custom menu-item-object-custom menu-item-has-children show-label rtm-menu-depth-0 link-disabled">
                        <a class="mm-next" data-target="#mm-4"></a><a><span class="main-nav-item-title main-title-with-desc">
                            <img id="plus_icon" src="https://www.mastechdigital.com/wp-content/uploads/2018/10/Plus_Blue2.png" alt="plus-Blue" width="12" height="13" />
                        </span></a>

                    </li>
                </ul></div>

            <div class="mm-panel mm-hasnavbar mm-highest" id="mm-1"><div class="mm-navbar"><a onClick={backSubmenu} class="mm-btn mm-prev" data-target="#mm-1"></a><a class="mm-title" href="#menu-nav-menu">DIGITAL STAFFING</a></div><ul class="sub-menu mm-listview mm-first mm-last">
                <li id="menu-item-16708" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/offshore-staffing-services/"><span class="main-nav-item-title main-title-with-desc">Offshore Staffing</span></a></li>
                <li id="menu-item-16710" class="menu-item-type-custom menu-item-object-custom show-label"><a href="https://www.mastechdigital.com/remote-staffing/"><span class="main-nav-item-title main-title-with-desc">MAS-REMOTE</span></a></li>
                <li id="menu-item-16711" class="menu-item-type-custom menu-item-object-custom show-label"><a href="https://www.mastechdigital.com/it-staffing-services/"><span class="main-nav-item-title main-title-with-desc">On-Premise Staffing</span></a></li>
                <li id="menu-item-18915" class="menu-item-type-custom menu-item-object-custom show-label"><a href="https://staffing.mastechdigital.com"><span class="main-nav-item-title main-title-with-desc">Browse Candidates</span></a></li>
            </ul></div>

            <div class="mm-panel mm-hasnavbar mm-hidden" id="mm-2"><div class="mm-navbar"><a onClick={backSubmenu} class="mm-btn mm-prev" data-target="#mm-2"></a><a class="mm-title" href="#menu-nav-menu">COMPANY</a></div><ul class="sub-menu mm-listview mm-first mm-last">
                <li id="menu-item-17031" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/inclusivity-and-diversity/"><span class="main-nav-item-title main-title-with-desc">Inclusivity and Diversity</span></a></li>
                <li id="menu-item-17032" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/corporate-governance-at-mastech-digital/"><span class="main-nav-item-title main-title-with-desc">Corporate Governance</span></a></li>
                <li id="menu-item-17030" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/csr/"><span class="main-nav-item-title main-title-with-desc">Social Responsibility</span></a></li>
            </ul></div>

            <div class="mm-panel mm-hasnavbar mm-hidden" id="mm-3"><div class="mm-navbar">
                <a onClick={backSubmenu} class="mm-btn mm-prev" data-target="#mm-3"></a>
                <a class="mm-title" href="#menu-nav-menu">INVESTORS</a></div><ul class="sub-menu mm-listview mm-first mm-last">
                <li id="menu-item-4270" class="investor-class menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/investors/"><span class="main-nav-item-title main-title-with-desc">INVESTORS DASHBOARD</span></a></li>
                <li id="menu-item-4269" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/press-releases/"><span class="main-nav-item-title main-title-with-desc">PRESS RELEASES</span></a></li>
                <li id="menu-item-15427" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/annual-meeting/"><span class="main-nav-item-title main-title-with-desc">ANNUAL MEETING</span></a></li>
                <li id="menu-item-4267" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/online-investor-kit/"><span class="main-nav-item-title main-title-with-desc">ONLINE INVESTOR KIT</span></a></li>
                <li id="menu-item-4274" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/sec-filings/"><span class="main-nav-item-title main-title-with-desc">SEC FILINGS</span></a></li>
                <li id="menu-item-5331" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/stock/"><span class="main-nav-item-title main-title-with-desc">STOCK</span></a></li>
                <li id="menu-item-4273" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/corporate-governance/"><span class="main-nav-item-title main-title-with-desc">CORPORATE GOVERNANCE</span></a></li>
                <li id="menu-item-4266" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/webcast-and-presentation/"><span class="main-nav-item-title main-title-with-desc">WEBCASTS AND PRESENTATIONS</span></a></li>
            </ul></div>


            <div class="mm-panel mm-hasnavbar mm-highest mm-current" id="mm-4"><div class="mm-navbar">
                <a onClick={backSubmenu} class="mm-btn mm-prev" data-target="#mm-4"></a><a class="mm-title" href="#menu-nav-menu"></a></div>
                <ul class="sub-menu mm-listview mm-first mm-last mt-4">
                <li id="menu-item-9966" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/case-studies-and-white-papers/"><span class="main-nav-item-title main-title-with-desc">RESOURCES</span></a></li>
                <li id="menu-item-2909" class="menu-item-type-custom menu-item-object-custom show-label"><a href="http://clients.datafrenzy.com/Mastech/?_ga=1.168930081.1441059710.1467018527%20"><span class="main-nav-item-title main-title-with-desc">JOBS</span></a></li>
                <li id="menu-item-4578" class="menu-item-type-post_type menu-item-object-page show-label"><a href="https://www.mastechdigital.com/contact/"><span class="main-nav-item-title main-title-with-desc">CONTACT</span></a></li>
                <li id="menu-item-2319" class="menu-item-type-custom menu-item-object-custom show-label"><a href="https://ehof.fa.us2.oraclecloud.com/fscmUI/faces/AtkHomePageWelcome"><span class="main-nav-item-title main-title-with-desc">LOGIN</span></a></li>
            </ul></div>

        </nav>
    )

}
export default DrawerSideBar;


export const SET_CANDIDATE_SEARCHED_DATA_REQUEST = 'SET_CANDIDATE_SEARCHED_DATA_REQUEST'
export const SET_CANDIDATE_SEARCHED_DATA_SUCCESS = 'SET_CANDIDATE_SEARCHED_DATA_SUCCESS'
export const SET_CANDIDATE_SEARCHED_DATA_FAILURE = 'SET_CANDIDATE_SEARCHED_DATA_FAILURE'
export const SET_CANDIDATE_SEARCHED_DATA = 'SET_CANDIDATE_SEARCHED_DATA'
export const SET_CANDIDATE_TOTAL = 'SET_CANDIDATE_TOTAL'
export const SET_CANDIDATE_COUNT = 'SET_CANDIDATE_COUNT'
export const SET_CANDIDATE_MASTER = 'SET_CANDIDATE_MASTER'
export const SET_CANDIDATE_TOTAL_SKILLS = 'SET_CANDIDATE_TOTAL_SKILLS'
export const SET_CANDIDATE_TOTAL_SKILLS_MASTER = 'SET_CANDIDATE_TOTAL_SKILLS_MASTER'
export const SET_CANDIDATE_CARD_SKILLS = 'SET_CANDIDATE_CARD_SKILLS'
export const SET_SKILL_SUGGESTIONS_LIST = 'SET_SKILL_SUGGESTIONS_LIST'
export const SET_CANDIDATE_LIMIT = 'SET_CANDIDATE_LIMIT'
export const SET_SELECTED_SKILL_SUGGESTION_OBJECT = 'SET_SELECTED_SKILL_SUGGESTION_OBJECT'
export const SET_SELECTED_SKILLS = 'SET_SELECTED_SKILLS'
export const SET_SKILLS_INFO = 'SET_SKILLS_INFO'
export const SET_RELEVENT_TITLE_STATUS = 'SET_RELEVENT_TITLE_STATUS'
export const SET_EXPERIENCE_STATUS = 'SET_EXPERIENCE_STATUS'
export const SET_REMOTE_STATUS = 'SET_REMOTE_STATUS'




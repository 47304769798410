import {
  SEARCH_DATA_DIALOG,
} from './searchDataDialogTypes'

export const searchDataDialogToggle = (toggleStatus) => {
  return {
    type: SEARCH_DATA_DIALOG,
    payload: toggleStatus
  }
}



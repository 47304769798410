import {
  SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST,
  SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS,
  SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE,
  SET_SEARCH_ROLE,
  SET_SEARCH_CITY_NAME,
  SET_SEARCH_JOB,
  SET_SEARCH_SKILLS,
  SET_SEARCH_ZIP_CODE,
  SET_SEARCH_RADIUS,
  SET_CLEAR_FILTER,
  SET_CLEAR_EXPERIENCE_LOADER,
  SET_SEARCH_STATUS_TRIGGER,
  SET_CANDIDATE_EXPERIENCE_LEVEL,
  SET_SEARCH_SIDE_SKILLS
} from './candidateSearchTypes'
import { totalCount } from "../../_service/jobListing";

// export const setCandidateTotalCountData = (data) => {
//   console.debug("candidate action fetch data", data)
//   return (dispatch) => {
//     dispatch(setCandidateTotalCountDataRequest())
//     totalCount(data)
//       .then(response => {
//         const totalCount = response.data
//         dispatch(setCandidateTotalCountDataSuccess(totalCount))
//       })
//       .catch(error => {
//         dispatch(setCandidateTotalCountDataFailure(error.message))
//       })
//   }
// }

// export const setCandidateTotalCountDataRequest = () => {
//   return {
//     type: SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST
//   }
// }

// export const setCandidateTotalCountDataSuccess = data => {
//   console.debug(" candidate data action", data)
//   return {
//     type: SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS,
//     payload: data
//   }
// }

// export const setCandidateTotalCountDataFailure = error => {
//   return {
//     type: SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE,
//     payload: error
//   }
// }


export const setSearchRole = (data) => {
  return {
    type: SET_SEARCH_ROLE,
    payload: data,
  }
}

export const setSearchCity = (data) => {
  return {
    type: SET_SEARCH_CITY_NAME,
    payload: data,
  }
}

export const setSearchJob = (data) => {
  return {
    type: SET_SEARCH_JOB,
    payload: data,
  }
}

export const setSearchSkills = (data) => {
  return {
    type: SET_SEARCH_SKILLS,
    payload: data,
  }
}

export const setSearchZipCode = (data) => {
  return {
    type: SET_SEARCH_ZIP_CODE,
    payload: data,
  }
}

export const setSearchRadius = (data) => {
  return {
    type: SET_SEARCH_RADIUS,
    payload: data,
  }
}

export const setClearFilter = (data) => {
  return {
    type: SET_CLEAR_FILTER,
    payload: data,
  }
}

export const setClearExperienceLoader = (data) => {
  return {
    type: SET_CLEAR_EXPERIENCE_LOADER,
    payload: data,
  }
}

export const setSearchStatusTrigger = (data) => {
  return {
    type: SET_SEARCH_STATUS_TRIGGER,
    payload: data,
  }
}



export const setCandidateExperienceLevel = (data) => {
  return {
    type: SET_CANDIDATE_EXPERIENCE_LEVEL,
    payload: data,
  }
}

export const setSearchSideSkills = (data) => {
  return {
    type: SET_SEARCH_SIDE_SKILLS,
    payload: data,
  }
}
import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { networkErrorDialogToggle, allSkillsDataDialogToggle, setCandidateData, setSelectedSkills } from '../../redux'
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 12,
      textAlign: "left",
      color: " #808080",
      opacity: "0.7",
      flex: "none",
      "& > h5": {
        float: "right",
        padding: "0px",
        position: "relative",
        right: "0",
        top: "-5px"
      }

    },
    pos: {
      marginBottom: 12,
      width: "35%",
      textAlign: "left",
      marginTop: "1rem",
      fontSize: "0.7rem",
      fontWeight: "500"
    },
  },
}));

function AllSkillsDataDialog(props) {
  const classes = useStyles();

  const [skillArray, setSkillArray] = useState([]);
  const [skillArrayMaster, setSkillMasterArray] = useState([]);
  const [searchedSkill, setSearchedSkill] = useState(null);

  function handleClose() {
    props.allSkillsDataDialogToggle(false);
    let detail = {
      refId: null,
      yearOfExp: null,
      zipCode: null,
      cityCountry: null,
      skills: [],
      jobTitle: null
    }
    props.setCandidateData(detail)
  }

  // function onChangeHandlerSkillSearch(e) {
  //   let filtered = props.skills.find(v => {
  //     // console.debug("filtered e.target.value", e.target.value)
  //     // console.debug("filtered skill v skillName", v.skillName)
  //     if (v.skillName.indexOf(e.target.value) > -1) {
  //       // if (v.skillName==e.target.value)  {
  //       console.debug("filtered skill", v)

  //     }
  //   })
  // }

  useEffect(() => {

    if (props.skills) {
      let counter = 1
      let s = props.skills.map(d => {
        return { "id": counter++, "selectedStatus": props.selectedCandiSkill ? props.selectedCandiSkill.find(f => f == d.skillName) : [], ...d }
        // return {"id":counter++, "selectedStatus": "java", ...d }
      })

      setSkillArray([...s])
      setSkillMasterArray([...s])
    }

  }, [props.skills])
  console.debug("proppppps skill dialog", props)
  function onchangeSkillSearch(e, value) {

    if (value) {
      setSearchedSkill(value)
      let fObj = { "selectedStatus": props.selectedCandiSkill.find(f => f == value), "skillName": value }
      setSkillArray([fObj])
    } else {
      setSkillArray(skillArrayMaster)
    }
  }
  const getSkillSkeleton = () => {
    let count = 35;
    let a = [];
    for (let i = 0; i < count; i++) {
      a.push((
        <Box className='float-left mr-2' sx={{ width: 100 }}>
          <Skeleton />
        </Box>
      ))
    }
    return a;
  }

  return (
    <Dialog
      open={props.skillDialogStatus}
      maxWidth={"sm"}
      fullWidth={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      <DialogContent className='p-0'>
        <div className='d-block p-1 primaryBacgrounf w-100'></div>
        <h5 className=" mb-0 ml-4 mt-4 subTitle w-75" title={props.jobTitle} variant="h5" component="h2">

          {props.jobTitle ? props.jobTitle :
            <Box sx={{ width: 100 }}>
              <Skeleton />
            </Box>
          }
          {/* {props.jobTitle ? props.jobTitle : "-"} */}
        </h5>
        <div className="detail-content pt-4 pr-4 pl-4 ">

          <div className={classes.title} style={{ width: "40%" }} color="textSecondary" >
            {/* Ref Id- {props.refId ? props.refId : "-"} */}
            Ref No- {props.refId ? props.refId :
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            }

          </div>

          <div className="w-25" color="textSecondary">
            <span> Year of exp:</span>
            <h6 className='font-weight-bold small'>{props.yearOfExp ? props.yearOfExp :
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            }</h6>
            {/* <h6>{props.yearOfExp ? props.yearOfExp : "-"}</h6> */}
          </div>
          <div className="w-25" color="textSecondary">
            <span>Zipcode:</span>
            <h6 className='font-weight-bold small'>{props.zipCode ? props.zipCode :
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            }</h6>
            {/* <h6>{props.zipCode ? props.zipCode : "-"}</h6> */}
          </div>
          {/* <div color="textSecondary">
            <span> City,Country:</span>
            <h6 className='font-weight-bold small'>{props.cityCountry ? props.cityCountry :
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            }</h6>
          </div> */}
          <div color="textSecondary">
            <span> City,State:</span>
            <h6 className='font-weight-bold small'>{props.city || props.state ? props.city + "," + props.state :
              <Box sx={{ width: 100 }}>
                <Skeleton />
              </Box>
            }</h6>
          </div>
        </div>
        <div className='border mb-4 ml-4 mr-4 pl-3 rounded tags-content'>
          <div className=" rounded">

            {/* <div className="col-md-11 p-0">
              <Autocomplete
                loading
                id="tags-filled"
                name="search"
                onChange={onchangeSkillSearch}
                className="search-skill"
                // autoComplete={true}
                freeSolo
                options={props.skills ? props.skills.map((option) => option.skillName) : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    name="search"
                    label="Search Skills"
                  />
                )}
              />
              <img src="/Vector.svg" className='position-absolute top10 right20' />
            </div> */}
            <div className="col-md-12 mt-4 p-0">
              <div className="height-15vh overflow-auto skillset-details">
                {skillArray.length ? skillArray.map(d =>
                  <span key={d.id} className={d.selectedStatus ? "border p-1 m-1 rounded secondaryBackground colorWhite  d-inline-block small" : "bg-light border d-inline-block  m-1 p-1 rounded small"}>{d.skillName}</span>
                ) :
                  <div className='d-inline-block'>
                    {getSkillSkeleton()}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose} >
          Cancel
        </Button>
      </DialogActions>
    </Dialog >
  );
}

// export default FileRenameDialog;
const mapStateToProps = state => {
  return {
    skillDialogStatus: state.candidateAllSkills.skillDialogStatus,
    refId: state.candidateAllSkills.refId,
    yearOfExp: state.candidateAllSkills.yearOfExp,
    state: state.candidateAllSkills.state,
    zipCode: state.candidateAllSkills.zipCode,
    cityCountry: state.candidateAllSkills.cityCountry,
    skills: state.candidateAllSkills.skills,
    jobTitle: state.candidateAllSkills.jobTitle,
    city: state.candidateAllSkills.city,
    skills: state.candidateAllSkills.skills,
    selectedCandiSkill: state.candidate.selectedCandiSkill,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    networkErrorDialogToggle: (toogleStatus) => dispatch(networkErrorDialogToggle(toogleStatus)),
    allSkillsDataDialogToggle: (jobId) => dispatch(allSkillsDataDialogToggle(jobId)),
    setCandidateData: (jobId) => dispatch(setCandidateData(jobId)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSkillsDataDialog)
import React from 'react';
import { searchDataDialogToggle } from '../../redux'
import { connect } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import SideBar from '../Sidebar/sideBar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '100%',
    },
  },
  paper:{
    "& >div >div":{
      width: '100%',
      maxWidth: '100%',
      margin: '0',
      height: '50%',
      position: 'absolute',
      bottom: '0',
    }
  },
  filterContainer:{
    padding: "0px"
  }
}));

function FilteredByDialoge(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.searchDialogStatus}
      maxWidth={"md"}
      fullWidth={true}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.paper}
    >
      <DialogContent className="pt-0 p-0">
      <SideBar />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    searchDialogStatus: state.searchDataDialog.searchDialogStatus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchDataDialogToggle: (jobId) => dispatch(searchDataDialogToggle(jobId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteredByDialoge)
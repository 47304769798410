import {
  ALL_SKILLS_DATA_DIALOG,
  SET_CANDIDATE_DETAIL
} from './allSkillsDataDialogTypes'

export const allSkillsDataDialogToggle = (toggleStatus) => {
  return {
    type: ALL_SKILLS_DATA_DIALOG,
    payload: toggleStatus
  }
}

export const setCandidateData = (data) => {
  return {
    type: SET_CANDIDATE_DETAIL,
    payload: data
  }
}


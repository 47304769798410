import {
  ALL_SKILLS_DATA_DIALOG,
  SET_CANDIDATE_DETAIL
} from './allSkillsDataDialogTypes'

const initialState = {
  skillDialogStatus: false,
  refId: null,
  yearOfExp: null,
  zipCode: null,
  cityCountry: null,
  jobTitle: null,
  skills: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SKILLS_DATA_DIALOG:
      return {
        ...state,
        skillDialogStatus: action.payload,
      }
      case SET_CANDIDATE_DETAIL:
        return {
          ...state,
          refId: action.payload.refId,
          yearOfExp: action.payload.yearOfExp,
          state: action.payload.state,
          city: action.payload.city,
          zipCode: action.payload.zipCode,
          cityCountry: action.payload.cityCountry,
          skills: action.payload.skills,
          jobTitle:action.payload.jobTitle,
        }
    default: return state
  }
}

export default reducer

import {
  SEARCH_DATA_DIALOG,
} from './searchDataDialogTypes'
const initialState = {
  searchDialogStatus: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DATA_DIALOG:
      return {
        ...state,
        searchDialogStatus: action.payload,
      }
    
    default: return state
  }
}

export default reducer

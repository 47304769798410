import React, { useEffect, useState } from 'react';
import Header from './Staffing/Header/header';
import Headerv2 from './Staffing/Header/Headerv2';

import {
	Route,
	Redirect,
} from "react-router-dom";
import DrawerSideBar from './Staffing/Header/drawerSidebar';

function PrivateRoute({ children, loginStatus, ...rest }) {

	const myRef = React.createRef();
	const [slideDrawer, setSlideDrawer] = useState(false)
	useEffect(() => {
		function handleClickOutside(event) {
			if (myRef.current && !myRef.current.contains(event.target)) {
				setSlideDrawer(false);
			} else {
				//   setSlideDrawer(true);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [myRef]);
	function drawerOpen() {
		setSlideDrawer(true)
	}

	return (
		loginStatus ? <Route
			{...rest}
			render={({ location }) =>
				<div>
					{slideDrawer && <DrawerSideBar myRef={myRef} />}
					<div className={slideDrawer ? "transform80 transition5 " : "transition5"}>
						{/* <Header drawerOpen={drawerOpen} /> */}
						<Headerv2 drawerOpen={drawerOpen}/>
						<div className="content-wrapper d-block ">
							{children}
						</div>
					</div>
				</div>
			}
		/> : 
		<Redirect to={{ pathname: "/" }} />

		// <Route
		// {...rest}
		// render={({ location }) =>
		// 	<div>
		// 		{slideDrawer && <DrawerSideBar myRef={myRef} />}
		// 		<div className={slideDrawer ? "transform80 transition5 " : "transition5"}>
		// 			<Header drawerOpen={drawerOpen} />
		// 			<div className="content-wrapper d-block ">
		// 				{children}
		// 			</div>
		// 		</div>
		// 	</div>
		// }
	// />

	);
}

export default PrivateRoute;

import React, { useState, useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Link, NavLink } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import moment from 'moment'
import { createCandidate } from "../_service/jobListing/createCandidate.service";
import { connect } from "react-redux";
import { networkErrorDialogToggle } from "../redux";
import { formatMs } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
function CandidateInfo(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [description, setDescription] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [companynameErr, setCompanynameErr] = useState("");
    const [jobTitleErr, setJobTitleErr] = useState("");
    const [candidateRequireErr, setCandidateRequireErr] = useState("");
    const [lastnameErr, setLastnameErr] = useState("");
    const [phoneErrMsg, setPhoneErrMsg] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [jobTypeId, setJobTypeId] = useState("")
    const [perContErr, setPerContErr] = useState("");
    const [remoteType, setRemoteType] = useState("")
    const [captchaValue, setCaptchaValue] = useState("")


    let history = useHistory();
    const CandidateRequired = [
        { title: 'Immediately' },
        { title: 'Within 7 days' },
        { title: 'Within 15 days' },
        { title: 'Within 30 days' },
    ];
    const EmpTypee = [
        { title: 'Permanent' },
        { title: 'Contract' },
    ];

    function goBck() {
        window.history.back();
    }
    function onChangeTextHandler(e) {
        e.preventDefault();

        if (e.target.name === "firstName") {
            setNameErr("")
            setFirstName(e.target.value);

        }
        if (e.target.name === "lastName") {
            setLastName(e.target.value);
            setLastnameErr("")
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
            setEmailErr("")
        }

        if (e.target.name === "companyName") {
            setCompanyName(e.target.value);
            setCompanynameErr("")
        }
        if (e.target.name === "jobTitle") {
            setJobTitle(e.target.value);
            setJobTitleErr("")
        }
        var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (e.target.name === "phoneNumber") {
            setPhoneErrMsg("")
            setPhoneNumber(e.target.value);
            if (e.target.value.match(phoneNum)) {
                setPhoneErrMsg("")
            } else {
                setPhoneErrMsg(<p className="d-block error-msg mt-1 pl-2">Please enter valid number</p>)
            }
        }
        if (e.target.name === "description") {
            setDescription(e.target.value);
        }
    }

    function onHandlerCandidate(e, value) {
        var today = new Date();
        var toDate;
        var fromDate;
        var y = today.getFullYear();

        switch (value) {
            case "Immediately":
                fromDate = today.toISOString().split('T')[0];
                toDate = today.toISOString().split('T')[0];
                break;

            case "Within 7 days":
                let Y = today.getFullYear();
                let M = (today.getMonth() + 1);
                let P = (today.getDate() + 7);
                let D = today.getDate();
                fromDate = today.toISOString().split('T')[0];
                toDate = new Date(today.setDate(today.getDate() + 7)).toISOString().split('T')[0];
                break;

            case "Within 15 days":
                let YY = today.getFullYear();
                let MM = (today.getMonth() + 1);
                let PP = (today.getDate() + 15);
                let DD = today.getDate();
                fromDate = today.toISOString().split('T')[0];
                toDate = new Date(today.setDate(today.getDate() + 15)).toISOString().split('T')[0];
                break;

            case "Within 30 days":
                let m = (today.getMonth() + 1);
                let p = today.getMonth();
                let d = today.getDate();
                fromDate = today.toISOString().split('T')[0];
                toDate = new Date(today.setDate(today.getDate() + 30)).toISOString().split('T')[0];
                break;

            default:
                toDate = moment(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).format("DD-MM-YYYY")
                fromDate = moment(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).format("DD-MM-YYYY")
                break;
        }

        setToDate(toDate)
        setFromDate(fromDate)
        setCandidateRequireErr("")
    }

    function submit(e) {
        e.preventDefault();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // var phoneno = /^\d{10}$/;
        var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        let data = new FormData();
        if (firstName) {
            data.append('firstName', firstName);
        } else {
            setNameErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
        }
        if (lastName) {
            data.append('lastName', lastName);
        } else {
            setLastnameErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
        }
        if (email) {
            if (mailformat.test(email)) {
                data.append('email', email);
            } else {
                setEmailErr(<div className="form-group m-0 ml-2">
                    <span className="d-block error-msg mt-1 pl-2">Please enter valid email</span>
                </div>)
            }
        } else {
            setEmailErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
            setPhoneErrMsg("")
        }
        if (companyName) {
            data.append('companyName', companyName);

        } else {
            setCompanynameErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
        }
        if (jobTitle) {
            data.append('jobTitle', jobTitle);
        } else {
            setJobTitleErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
        }
        if (phoneNumber) {
            if (phoneNumber.match(phoneNum)) {
                setPhoneErrMsg("")
                data.append('phoneNumber', phoneNumber);

            } else {
                setPhoneErrMsg(<p className="d-block error-msg mt-1 pl-2">Please enter valid number</p>)
            }
        } else {
            setPhoneErrMsg("")
        }

        if (description) {
            data.append('description', description);
        }
        if (jobTypeId == 2 || jobTypeId == 1) {
            setPerContErr("")
            data.append('jobTypeId', jobTypeId);
        } else {
            setPerContErr(<div className="form-group m-0 ml-2 w-100">
                <span className="error-msg">Required field *</span>
            </div>)
        }
        if (toDate && fromDate) {
            data.append('fromDate', fromDate);
            data.append('toDate', toDate);

        } else {
            setCandidateRequireErr(<div className="form-group m-0 ml-2">
                <span className="d-block error-msg mt-1 pl-2">Required field *</span>
            </div>)
        }
        if (props.remoteStatus) {
            data.append('remote', props.remoteStatus == true ? 1 : 0);
        }

        if (props.selectedParticularCandidate) {
            props.selectedParticularCandidate.forEach((val, i) => {
                data.append(`candidate[${i}][city][cityName]`, val.city.cityName);
                data.append(`candidate[${i}][country][country]`, val.country.country);
                data.append(`candidate[${i}][job][jobTitle]`, val.job.jobTitle);
                data.append(`candidate[${i}][state][stateName]`, val.state.stateName);
                data.append(`candidate[${i}][zipcode][zipcode]`, val.zipCode.zipCode);
                data.append(`candidate[${i}][workExperience]`, val.workExperience);
                data.append(`candidate[${i}][refId]`, val.refId);
                val.skills.forEach((d, j) => { data.append(`candidate[${i}][skills][${j}][skillName]`, d.skillName) })
            });
        }

        if (firstName && lastName && email && mailformat.test(email) && toDate && fromDate && companyName && jobTitle && jobTypeId) {

            setSpinner(true)
            createCandidate(data).then(function (response) {
                setSpinner(false)
                history.push("/thank-you");

            })
                .catch(function (error) {
                    console.log(error);
                    console.log(JSON.stringify(error));
                    console.log(error.response);
                    props.networkErrorDialogToggle(!props.dialogStatus);

                });
        }
    }

    function onHandlerType(e, value) {
        setPerContErr("")
        if (value == undefined) {
            if (e.target.value == "Permanent" || e.target.value == "permanent") {
                setJobTypeId(1)
            }
            if (e.target.value == "Contract" || e.target.value == "contract") {
                setJobTypeId(2)
            }
        } else {
            if (value == "Permanent" || value == "permanent") {
                setJobTypeId(1)
            } else {
                setJobTypeId(2)
            }
        }

        // setJobTypeId(value == undefined ?
        //     (e.target.value == "Permanent" || e.target.value == "permanent") ? 1 : 2 :
        //     (value == "Permanent" || value == "permanent") ? 1 : 2);

    }

    function onChange(value) {
        setCaptchaValue(value)
        console.log("Captcha value:", value);
    }


    return (
        <div className=" main-container position-absolute w-100">
            <div className="row m-0 submitform-container position-relative w-100 d-flex align-items-start flex-row">
                <div className="col-lg-7 col-md-12 col-sm-12 col-xl-7 info-container p-4 p-lg-5 p-md-4 p-sm-4">
                    <span onClick={goBck} className="d-flex"><img src="./img/backarrow.svg" className="mr-2" /> Back to search result</span>
                    <Typography className="heading-bold font-weight-bold mt-4" color="textSecondary" gutterBottom>
                        Last step, please provide your contact information to receive the full details of the candidate profiles you selected.
                    </Typography>

                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 p-0">
                        <form autoComplete="off" className="form-data-content">
                            <div className="row col-md-12 mb-0 mb-lg-3 mb-xl-3 mb-md-0 mb-sm-0 p-0">
                                <div className=" col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">
                                    <TextField className="text-form w-100" id="filled-basic" label="First Name *" variant="filled" name="firstName"
                                        value={firstName} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {nameErr}
                                </div>
                                <div className=" col-md-6 col-sm-12 col-lg-6 col-xl-6 col-lg-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">

                                    <TextField className="text-form w-100 mr-0" id="filled-basic" label="Last Name *" variant="filled"
                                        name="lastName" value={lastName} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {lastnameErr}
                                </div>
                            </div>
                            <div className="row col-md-12 mb-0 mb-lg-3 mb-xl-3 mb-md-0 mb-sm-0 p-0">
                                <div className=" col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">
                                    <TextField className="text-form w-100" id="filled-basic" label="Company Name *" variant="filled"
                                        name="companyName" value={companyName} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {companynameErr}
                                </div>
                                <div className="  col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">

                                    <TextField className="text-form w-100 mr-0" id="filled-basic" label="Job Title *" variant="filled"
                                        name="jobTitle" value={jobTitle} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {jobTitleErr}
                                </div>
                            </div>
                            <div className="row col-md-12 mb-0 mb-lg-3 mb-xl-3 mb-md-0 mb-sm-0 p-0">
                                <div className="  col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">
                                    <TextField className="text-form w-100" id="filled-basic" label="E-mail *" variant="filled"
                                        name="email" value={email} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {emailErr}
                                </div>
                                <div className="  col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">

                                    <TextField className="text-form w-100 mr-0" id="filled-basic" label="Business Phone (Optional)" variant="filled"
                                        name="phoneNumber" value={phoneNumber} onChange={(e) => { onChangeTextHandler(e) }} />
                                    {phoneErrMsg}
                                </div>
                            </div>

                            <div className="row col-md-12 mb-0 mb-lg-3 mb-xl-3 mb-md-0 mb-sm-0 p-0">
                                <div className="  col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">
                                    <img src="/img/polygon.svg" className="position-absolute polygonicon" />

                                    <Autocomplete
                                        id="tags-filled"
                                        onChange={onHandlerCandidate}
                                        className="bg-white border-0  rounded-pill w-100 skillset"
                                        options={CandidateRequired.map((option) => option.title)}
                                        disableClearable
                                        freeSolo
                                        limit={2}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Timeframe To Hire *"
                                            />
                                        )}
                                    />
                                    {candidateRequireErr}
                                </div>

                                <div className="  col-md-6 col-sm-12 col-lg-6 col-xl-6 mb-md-3 mb-sm-3 mb-lg-0 mb-3">
                                    <img src="/img/polygon.svg" className="position-absolute polygonicon" />

                                    <Autocomplete
                                        id="tags-filled"
                                        onChange={onHandlerType}
                                        className="bg-white border-0  rounded-pill w-100 skillset"
                                        options={EmpTypee.map((option) => option.title)}
                                        freeSolo
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={onHandlerType}
                                                variant="filled"
                                                label="Employment Type *"
                                            />
                                        )}
                                    />
                                    {perContErr}
                                </div>
                            </div>

                            <div className="row col-md-12 mb-3 mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3 p-0">
                                <div className=" col-md-12 mb-3">
                                    <TextField className="text-form textarea-form w-100 rounded " id="filled-basic" label="Please add any additional information (Optional)" variant="filled"
                                        name="description" value={description} onChange={(e) => { onChangeTextHandler(e) }} />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-3 mb-lg-3 mb-xl-3 mb-md-3 mb-sm-3 ">
                                <ReCAPTCHA
                                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                    sitekey="6LdbV04gAAAAAIq7nfnJrS_nJlIrGLNW2bEaH9XU"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 text-right mb-3 pr-4">
                                <button className={captchaValue == "" || captchaValue == null || captchaValue == undefined ? "searchDisabled" : "search colorWhite"} onClick={submit} disabled={captchaValue == "" || captchaValue == null || captchaValue == undefined ? true : false}>
                                    Submit
                                    {spinner && <i className="fa fa-spinner fa-spin ml-2"></i>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="image-container col-md-12 col-sm-12 col-lg-5 col-xl-5 d-sm-none d-lg-block d-md-block d-xl-block d-none">
                    <img src="./img/GraphicWorker.svg" />
                </div>
            </div>

        </div>
    );
}
const mapStateToProps = state => {
    return {
        candidateSelectedData: state.candidateSelectedId.selectedFile,
        candidateData: state.candidate.candidateList,
        remoteStatus: state.candidate.remoteStatus,
        selectedParticularCandidate: state.candidateSelectedId.selectedParticularCandidate

    }
}
const mapDispatchToProps = dispatch => {
    return {
        networkErrorDialogToggle: (toggleStatus) => dispatch(networkErrorDialogToggle(toggleStatus)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateInfo)
// export default CandidateInfo;

import axios from "axios";
export async function totalCount(data) {
    let prarmTempObj = {};
    prarmTempObj = data;
    const response = await axios.post(
        "https://staffing-admin.mastechdigital.com/api/totalCount/V6",
        // "https://staffing-admin.mastechdigital.com/api/totalCount/V5",
        // "https://staffing-admin.mastechdigital.com/api/totalCount/V4",
        // "https://staffing-admin.mastechdigital.com/api/totalCount/V3",
        data,
        {
            // params: prarmTempObj,

        }

    ).then(function (response) {

        console.log(response);
        console.log('sandip')
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });

    return response;
}
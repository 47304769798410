import React, { useState, useEffect } from "react";
import Link from '@material-ui/core/Link';

function Headerv2(props) {


  return (
    // <header className=" d-inline-block w-100 position-fixed bg-white z-index ">


    // <div className=" z-index top25 float-left left-text logo-main-wrapper pr-0 pl-0 pr-lg-5 pl-lg-5 pr-sm-0 pl-sm-0 pr-md-5 pl-md-5 ml-4 ml-lg-0 ml-sm-4 ml-md-0 ml-xl-0
    // mt-3 mt-lg-0 mt-sm-3 mt-md-0 mt-xl-0 mb-3 mb-lg-0 mb-sm-3 mb-md-0 mb-xl-0 overflow-hidden position-relative w-25">
    // <div className="logo-container h-auto w-100">

    // </div>

    // </div>
    // <div className="d-inline-block d-lg-none d-md-none d-sm-inline-block d-xl-none float-right mr-4 mt-3 mb-3 fontSizex-large" onClick={props.drawerOpen}>&#9776;</div>


    <header className="container-fluid d-inline-block w-100 position-fixed bg-white z-index ">
      <div className="row pt-1">
        <div className="col-md-12">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="align-items-center align-items-lg-end align-items-md-center align-items-sm-center align-items-xl-end bg-transparent d-flex justify-content-center justify-content-lg-end justify-content-md-end justify-content-sm-center justify-content-xl-end position-relative row">
                  <div className="columns float-right large-12 position-relative">
                    <ul className="social_links m-0 p-0">
                      <li className="fb d-inline-block"><a href="https://www.facebook.com/mastechdigital/" className="border-left d-block pt-2 pr-2 pl-2 pb-2">
                        <img alt="facebook" src="./img/twitter3.svg" /></a></li>
                      <li className="tw d-inline-block"><a href="https://twitter.com/Mastech_Digital" className="border-left d-block pt-2 pr-2 pl-2 pb-2">
                        <img alt="twitter" src="./img/twitter.svg" /></a></li>
                      <li className="lik d-inline-block"><a href="https://www.linkedin.com/company/mastech?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A3684%2Cidx%3A2-2-3%2CtarId%3A1473429750709%2Ctas%3Amastech%20" className="border-left d-block pt-2 pr-3 pl-3 pb-2">
                        <img alt="linkedin" src="./img/twitter4.svg" /></a></li>
                      <li className="ins d-inline-block"><a href="https://www.instagram.com/mastech.digital" className="border-left d-block pt-2 pr-2 pl-2 pb-2">
                        <img alt="instagram" src="./img/twitter1.svg" /></a></li>
                      <li className="yt d-inline-block"><a href="https://www.youtube.com/channel/UCMG3A8O1i5dso0nFUaqaPnw" className="border-left border-right d-block pt-2 pr-2 pl-2 pb-2">
                        <img alt="youtube" src="./img/twitter2.svg" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="align-items-center align-items-sm-center align-items-lg-baseline col-7 col-md-2 col-sm-7 col-xl-2 col-xxl-2 d-flex d-sm-flex d-lg-flex ">
                <div className="row w-100 ">
                  <div className="col-12 logo p-0">
                    <Link href="https://www.mastechdigital.com//">
                      {/* <a href="https://www.mastechdigital.com//" title="Mastech Digital"> */}

                      <img alt="logo" className="w-100" src="/companyLogo.png" />
                      {/* <img alt="logo" className="w-100" src="https://www.mastechdigital.com/wp-content/uploads/2019/01/Mastech-Logo334-78.png" /> */}
                      {/* </a> */}
                    </Link>
                  </div>
                </div>

              </div>
              <div className="col-md-12 col-xl-10 col-xxl-10 col-sm-5 col-5">
                <div className="row">
                  <div className="col-12">

                    <div className="d-inline-block d-lg-none d-md-inline-block d-sm-inline-block d-xl-none float-right mr-4 mt-3 mb-3 fontSizex-large" onClick={props.drawerOpen}>&#9776;</div>


                    <div className="row">
                      <div className="col-12 p-0">
                        <nav id="rtm-navigation" className="navigation main-navigation menu-expand-lg">


                          <div id="nav" className="  menu-nav-menu d-lg-block d-md-none d-none d-sm-none d-xl-block">
                            <ul id="menu-nav-menu" className="float-right mb-0 nav-menu p-0 position-relative rtm-menu pt-4">

                              <li id="menu-item-11594" className=" letterspacing d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://mastechinfotrellis.com/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">DIGITAL TRANSFORMATION</span></a></li>

                              <li id="menu-item-11594" className=" letterspacing staff  d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a className=" " href="https://www.mastechdigital.com/it-staffing-services/"><span className=" font-weight-normal main-nav-item-title main-title-with-desc">DIGITAL STAFFING</span></a>

                                <div class=" staffing position-absolute  top52 borderTop">
                                  <a class=" a dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/offshore-staffing/"><span className="hoverMarginLeft">Offshore Staffing</span></a>
                                  <a class=" a dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/remote-staffing/"><span className="hoverMarginLeft">MAS-REMOTE</span></a>
                                  <a class="a dropdown-item text-secondary border-bottom p-3  headerHover" href="https://www.mastechdigital.com/it-staffing-services/"><span className="hoverMarginLeft">On-Premise Staffing</span></a>
                                </div>


                              </li>
                              <li id="menu-item-11594" className=" letterspacing d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://www.mastechdigital.com/digital-learning-services/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">DIGITAL LEARNING</span></a></li>
                              <li id="menu-item-11594" className=" letterspacing comp d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://www.mastechdigital.com/company/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">COMPANY</span></a>
                                <div class=" company position-absolute  top52 borderTop">
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/inclusivity-and-diversity/"><span className="hoverMarginLeft">Inclusivity and Diversity</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/corporate-governance-at-mastech-digital/"><span className="hoverMarginLeft">Corporate Governance</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/csr/"><span className="hoverMarginLeft">Social Responsibility</span></a>
                                </div>
                              </li>


                              <li id="menu-item-11594" className=" letterspacing inv d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="#"><span className="font-weight-normal main-nav-item-title main-title-with-desc">INVESTORS</span></a>

                                <div class=" investors position-absolute top52 borderTop">
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/investors/"><span className="hoverMarginLeft">INVESTORS DASHBOARD</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/press-releases/"><span className="hoverMarginLeft">PRESS RELEASES</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/annual-meeting/"><span className="hoverMarginLeft">ANNUAL MEETING</span></a>

                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/online-investor-kit/"><span className="hoverMarginLeft">ONLINE INVESTOR KIT</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/sec-filings/"><span className="hoverMarginLeft">SEC FILINGS</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/stock/"><span className="hoverMarginLeft">STOCK</span></a>

                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/corporate-governance/"><span className="hoverMarginLeft">CORPORATE GOVERNANCE</span></a>
                                  <a class="dropdown-item text-secondary border-bottom p-3 headerHover" href="https://www.mastechdigital.com/webcast-and-presentation/"><span className="hoverMarginLeft">WEBCASTS AND PRESENTATIONS</span></a>

                                </div>


                              </li>
                              <li id="menu-item-11594" className=" letterspacing d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://www.mastechdigital.com/newsroom/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">NEWSROOM</span></a></li>
                              <li id="menu-item-11594" className=" letterspacing d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://www.mastechdigital.com/careermain/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">CAREERS</span></a></li>
                              <li id="menu-item-11594" className=" letterspacing d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a href="https://www.mastechdigital.com/blog/"><span className="font-weight-normal main-nav-item-title main-title-with-desc">BLOGS</span></a></li>




                              <li id="menu-item-11594" className=" sign d-inline position-relative ml-3 mr-2 menu-item menu-item-type-custom menu-item-object-custom regularmenu  show-label rtm-menu-depth-0 d-inline-block float-left">
                                <a>
                                  <span className="font-weight-normal main-nav-item-title main-title-with-desc">
                                    {/* <img alt="plus-icon" src="/img/plus.svg" /> */}
                                    <button className="hoverPlusMinus border-0">
                                      <span></span>
                                      <span></span>
                                    </button>
                                  </span>
                                </a>

                                <div class=" plus position-absolute right30 top52 borderTop">
                                  <a class="dropdown-item text-secondary border-bottom p-3  headerHover" href="https://www.mastechdigital.com/case-studies-and-white-papers/"><span className="hoverMarginLeft">RESOURCES</span></a>
                                  <a class="dropdown-item text-secondary border-bottom  p-3  headerHover" href="http://clients.datafrenzy.com/Mastech/?_ga=1.168930081.1441059710.1467018527%20"><span className="hoverMarginLeft">JOBS</span></a>
                                  <a class="dropdown-item text-secondary border-bottom  p-3  headerHover" href="https://www.mastechdigital.com/contact/"><span className="hoverMarginLeft">CONTACT</span></a>
                                  <a class="dropdown-item text-secondary border-bottom  p-3  headerHover" href="https://ehof.fa.us2.oraclecloud.com/fscmUI/faces/AtkHomePageWelcome"><span className="hoverMarginLeft">LOGIN</span></a>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </nav>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>





  );
}

export default Headerv2;

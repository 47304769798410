import axios from "axios";
export async function createCandidate(postData) {
    const response = await axios.post(
        "https://staffing-admin.mastechdigital.com/api/enquiry",
        postData,

    ).then(function (response) {
        console.log(" ROle create success response");
        console.log(response);
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });

    return response;
}
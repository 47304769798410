import axios from "axios";
export async function getCandidate(data) {
    console.debug(data)
    let prarmTempObj = {};
    prarmTempObj = data;
    const response = await axios.post(
       "https://staffing-admin.mastechdigital.com/api/get-candidate-json/V4",
        data,
        {
            // params: prarmTempObj,

        }

    ).then(function (response) {

        console.log(response);
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });

    return response;
}
import {
  SET_CANDIDATE_SELECTED_ID,
  SET_SELECTED_CANDIDATE_DATA
} from './candidateSelectedTypes'

const initialState = {
  selectedFile:[],
  selectedParticularCandidate:[],
};

function candidateSelectedId(state = initialState, action) {
  switch (action.type) {
    case SET_CANDIDATE_SELECTED_ID:
    {
      return {
        ...state,
        selectedFile:action.payload,
        // selectedFile: [...state.selectedFile, action.payload]
      }
    }
    case SET_SELECTED_CANDIDATE_DATA:
    {
      return {
        ...state,
        selectedParticularCandidate:action.payload,
      }
    }
 
    default:
      return state
  }
}

export default candidateSelectedId;
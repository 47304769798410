import {
  NETWORK_ERROR_DIALOG_TOGGLE,
} from './networkErrorDialogTypes'

const initialState = {
  dialogStatus: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_ERROR_DIALOG_TOGGLE:
      return {
        ...state,
        dialogStatus: action.payload,
      }
    default: return state
  }
}

export default reducer

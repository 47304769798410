import { combineReducers } from 'redux'
import candidateReducer from './candidate/candidateReducer'
import candidateSelectedReducer from './candidateSelected/candidateSelectedReducer'
import networkErrorDialogReducer from './networkErrorDialog/networkErrorDialogReducer'
import candidateSearch from './candidateMaster/candidateSearchReducer'
import candidateAllSkills from './allSkillsDataDialog/allSkillsDataDialogReducer'
import searchDataDialog from './searchDataDialog/searchDataDialogReducer'
const rootReducer = combineReducers({
  candidate: candidateReducer,
  candidateSelectedId:candidateSelectedReducer,
  networkErrorDialogReducer:networkErrorDialogReducer,
  candidateSearch:candidateSearch,
  candidateAllSkills:candidateAllSkills,
  searchDataDialog:searchDataDialog
  
})

export default rootReducer

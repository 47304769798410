import { FormGroup, FormControlLabel, Checkbox, Radio, RadioGroup } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { jobFilter, totalCount, jobFilterV1, totalCountV4 } from "../../_service/jobListing";
import { getExperienceList } from "../../_service/jobListing/experienceList.service";
import {
    setCandidateSearchedData, setSearchRole, setSearchZipCode, setSearchRadius, setCandidateCardSkills,
    networkErrorDialogToggle, setCandidateCount, setCandidateTotalSkills, setCandidateExperienceLevel,
    setSkillSuggestionsList, setCandidateTotal, setCandidateTotalSkillSuggestionMaster, setCandiadteMaster,
    setSearchCity, setSearchJob, setSearchSkills, setClearFilter, setClearExperienceLoader, setSelectedSkills,
    setCandidateLimit, setReleventTitleStatus, setExperienceStatus, setCandiadteSelectedId, searchDataDialogToggle,
    setSearchSideSkills
} from "../../redux";
import { connect } from "react-redux";

function SideBar(props) {
    // const Sort = [{ label: "Relevent Matching" }, { label: "Experience" }]
    const Sort = [{ label: "Relevant Title" }, { label: "Relevant Matching" }]
    const experienceSorting = [{ label: "Relevance" }, { label: "Experience- low to high" }, { label: "Experience- high to low" }]
    const [selectedSkill, setSelectedSkill] = useState(props.searchSkills)
    const [skillListSet, setSkillListSet] = useState([])
    const [ExperienceLevel, setExperienceLevel] = useState([])
    const [moreLess, setMoreLess] = useState("Show more")
    const [selectedExperience, setSelectedExperience] = useState([]);
    const [experience, setExperience] = useState(props.masterCandidateData);
    const [relDefaultValue, setrelDefaultValue] = useState("Relevant Title");
    const [expDefaultValue, setexpDefaultValue] = useState("Relevance");

    useEffect(function () {
        console.debug("propssssssssss", props.searchSkills)
        if (props.searchSkills) {
            setSelectedSkill(props.searchSkills)
            props.setSearchSideSkills([...selectedSkill])
        }
        props.setExperienceStatus("Relevance")

        getExperienceList().then(function (response) {
            {
                if (response.data.data) {

                    setExperienceLevel(response.data.data.map((temp) => {
                        return temp;
                    }));
                } else {
                    setExperienceLevel("")

                }

            }
        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            props.networkErrorDialogToggle(!props.dialogStatus);
            setExperienceLevel("")
        });

    }, []);

    useEffect(function () {
        if (props.clearFilter) {
            console.debug("clearFilter", props.clearFilter)
            setSelectedExperience([])
            setSelectedSkill(props.searchSkills)
            setrelDefaultValue("Relevant Title")
            setexpDefaultValue("Relevance")
            props.setClearFilter("")
            console.debug("clearFilter selectedSkill", props.searchSkills);
            props.setSearchSideSkills([...selectedSkill])

        }
    }, [props.clearFilter]);

    useEffect(function () {
        if (relDefaultValue == "Experience") {
            console.debug("clearFilter clearLoaderFilter outside sidebar", props.clearLoaderFilter)
            if (props.clearLoaderFilter == "loader") {
                setrelDefaultValue("Relevant Title")
                setexpDefaultValue("Relevance")
                props.setClearExperienceLoader("")
                console.debug("clearFilter clearLoaderFilter inside sidebar", props.clearLoaderFilter)
            }
        }

    }, [props.clearLoaderFilter]);

    const onChangeHandler = (e) => {
        let formData = new FormData();
        let sExp = selectedExperience;
        var dataVal = []
        let findObj = ExperienceLevel.find(d => e.target.value == d.id);
        if (selectedSkill != undefined) {
            dataVal = [...selectedSkill]
        }
        if (selectedExperience.find(d => e.target.value == d.id)) {
            let a = selectedExperience.filter(r => r.id != e.target.value);
            sExp = [...a]
            setSelectedExperience([...a])
        } else {
            sExp = [...sExp, findObj];
            setSelectedExperience([...sExp]);
        }
        let filteredSkills = dataVal.find(d => {
            if (d != props.searchRole) {
                dataVal = [...selectedSkill, props.searchRole]
            }
        })

        if (relDefaultValue == "Relevant Title") {
            if (props.searchRole) {
                formData.append('job', props.searchRole);
            }
        }
        if (props.searchZipCode || props.searchRadius) {
            formData.append('zipCode', props.searchZipCode);
            formData.append('radius', props.searchRadius);
        }
        formData.append('limit', 216);
        if (relDefaultValue == "Relevant Matching") {
            if (dataVal.length) {
                dataVal.forEach((val, i) => {
                    formData.append(`skills[${i}]`, val);
                });
            }
        } else {
            if (selectedSkill != undefined) {
                if (selectedSkill.length) {
                    selectedSkill.forEach((val, i) => {
                        formData.append(`skills[${i}]`, val);
                    });


                }
            }
        }
        if (sExp.length) {
            sExp.forEach((val, i) => {
                formData.append(`experienceId[${i}]`, val.id);
            });
        }
        props.setCandidateExperienceLevel(sExp)
        props.setCandidateLimit(216)
        props.setOpenBackdrop(true)
        versionFourAndSix(formData)

    }

    // _______________- RELEVENT SORTING ________________________

    function releventSort(e) {
        var dataVal = []
        if (selectedSkill) {
            dataVal = [...selectedSkill]
        }
        let formData = new FormData();
        if (e.target.value == "Relevant Matching") {
            setrelDefaultValue("Relevant Matching")
            console.debug("releventSort", props)
            console.debug("releventSort dataVal", dataVal)
            setSelectedSkill(dataVal)
            let filteredSkills = dataVal.find(d => {
                if (d != props.searchRole) {
                    dataVal = [...selectedSkill, props.searchRole]
                }
            })
            if (props.searchZipCode || props.searchRadius) {
                formData.append('zipCode', props.searchZipCode);
                formData.append('radius', props.searchRadius);

            }
            formData.append('limit', 216);
            if (dataVal.length) {
                dataVal.forEach((val, i) => {
                    formData.append(`skills[${i}]`, val);
                });

            }
            if (props.candidateExperienceLevel.length) {
                props.candidateExperienceLevel.forEach((val, i) => {
                    formData.append(`experienceId[${i}]`, val.id);
                });
            }
            props.setCandidateLimit(216)
            props.setOpenBackdrop(true)
            props.setReleventTitleStatus(true)
            versionFourAndSix(formData)

            // props.setCandidateSearchedData([...masterSortData])
        }
        if (e.target.value == "Relevant Title") {
            setrelDefaultValue("Relevant Title")
            if (props.searchRole) {
                formData.append('job', props.searchRole);
            }

            if (props.searchZipCode || props.searchRadius) {
                formData.append('zipCode', props.searchZipCode);
                formData.append('radius', props.searchRadius);

            }
            formData.append('limit', 216);
            if (selectedSkill != undefined) {
                if (selectedSkill.length) {
                    selectedSkill.forEach((val, i) => {
                        formData.append(`skills[${i}]`, val);
                    });

                }
            }
            if (props.candidateExperienceLevel.length) {
                props.candidateExperienceLevel.forEach((val, i) => {
                    formData.append(`experienceId[${i}]`, val.id);
                });
            }
            props.setCandidateLimit(216)
            props.setOpenBackdrop(true)
            props.setReleventTitleStatus(false)
            versionFourAndSix(formData)
            // releventVersion(formData)
        }
    }

    function versionFourAndSix(data) {
        props.setCandiadteSelectedId([])
        props.setOpenBackdrop(true)

        jobFilter(data).then(function (response) {
            if (Array.isArray(response.data.data)) {
                let candidate = response.data
                props.setOpenBackdrop(false)
                props.setCandidateCount(candidate.totalList);
                if (expDefaultValue == "Experience- high to low") {
                    var hightTolow = candidate.data.sort((a, b) => {
                        var c = a.workExperience;
                        var d = b.workExperience;
                        return d - c;

                    })
                    props.setCandidateSearchedData([...hightTolow])
                }
                if (expDefaultValue == "Experience- low to high") {
                    var lowTohigh = candidate.data.sort((a, b) => {
                        var c = a.workExperience;
                        var d = b.workExperience;
                        return c - d;

                    })
                    props.setCandidateSearchedData([...lowTohigh])
                }
                if (expDefaultValue == "Relevance") {
                    props.setCandiadteMaster(candidate.data)
                    props.setCandidateSearchedData(candidate.data);
                }
                props.setClearFilter("")

            }
        })
        totalCount(data).then(function (response) {
            let candidate = response.data
            // props.setCandidateTotal(candidate.total);
            props.setCandidateTotal(candidate.total);


        }).catch(function (error) {
            console.log(error);
            console.log(JSON.stringify(error));
            console.log(error.response);
            props.networkErrorDialogToggle(!props.dialogStatus);
        });

    }

    function experienceSort(e) {
        var experienceSortData = [...props.candidateData]
        var masterExperienceSortData = [...props.masterCandidateData]
        props.setExperienceStatus(e.target.value)
        if (e.target.value == "Experience- high to low") {
            setexpDefaultValue("Experience- high to low")
            var hightTolow = experienceSortData.sort((a, b) => {
                var c = a.workExperience;
                var d = b.workExperience;
                return d - c;

            })
            props.setCandidateSearchedData([...hightTolow])

        }
        if (e.target.value == "Experience- low to high") {
            setexpDefaultValue("Experience- low to high")
            var lowTohigh = experienceSortData.sort((a, b) => {
                var c = a.workExperience;
                var d = b.workExperience;
                return c - d;

            })
            props.setCandidateSearchedData([...lowTohigh])

        }
        if (e.target.value == "Relevance") {
            setexpDefaultValue("Relevance")
            props.setCandidateSearchedData([...masterExperienceSortData])
        }
        props.setClearFilter("")
    }

    const skillsFilterHandler = (e) => {
        if (e.target.name == "skills") {
            var sSkill = []

            if (selectedSkill != undefined) {
                sSkill = [...selectedSkill]
                if (selectedSkill.find(d => e.target.value == d)) {
                    let a = selectedSkill.filter(r => r != e.target.value);
                    sSkill = [...a]
                    setSelectedSkill([...a])
                } else {
                    sSkill = [...sSkill, e.target.value];
                    setSelectedSkill([...sSkill]);
                }
            }
            else {
                sSkill = [...sSkill, e.target.value];
                setSelectedSkill([...sSkill]);
            }
            let formData = new FormData();
            console.debug(props.searchRol)
            if (relDefaultValue == "Relevant Title") {
                if (props.searchRole) {
                    formData.append('job', props.searchRole);
                }
            } else {
                sSkill = [...sSkill, props.searchRole];

            }
            if (props.searchZipCode || props.searchRadius) {
                formData.append('zipCode', props.searchZipCode);
                formData.append('radius', props.searchRadius);

            }

            formData.append('limit', 216);
            if (sSkill.length) {
                sSkill.forEach((val, i) => {
                    formData.append(`skills[${i}]`, val);
                });
            }
            if (props.candidateExperienceLevel.length) {
                props.candidateExperienceLevel.forEach((val, i) => {
                    formData.append(`experienceId[${i}]`, val.id);
                });
            }
            console.debug("searchSkills", sSkill)
            console.debug("searchSkills", selectedSkill)
            props.setSearchSideSkills([...sSkill])
            props.setSelectedSkills([...sSkill])
            versionFourAndSix(formData)

        }
    }

    function showMoreLess(e) {
        let totalSkill = [...props.candidateSkillsSuggestion]
        let masterSkill = [...props.candidateTotalSkillSuggestionMaster]
        if (totalSkill.length < masterSkill.length) {
            var value = 0;
            if (totalSkill.length + 10 > masterSkill.length) {
                value = masterSkill.length;
                setMoreLess("Show Less");
            } else {
                value = totalSkill.length + 10
            }
            let skills = masterSkill.slice(totalSkill.length, value)
            totalSkill = [...totalSkill, ...skills]
            props.setSkillSuggestionsList([...totalSkill])
        } else {
            let skills = totalSkill.slice(0, 10)
            totalSkill = [...skills]
            setMoreLess("Show More");
            props.setSkillSuggestionsList([...totalSkill])
        }
    }
    function openSearchDialogHandler() {
        props.searchDataDialogToggle(!props.searchDialogStatus)
    }

    return (
        // <div className="menu-list d-sm-none d-lg-block d-md-none d-xl-block d-block">
        // <div className={props.filter ? "bg-white d-block menu-list  position-absolute " : "d-none"}>
        <div className="bg-white d-block menu-list  position-absolute ">
            <h4 className="pl-4 pr-4 pt-4 pl-sm-4 pr-sm-4 pt-sm-4 pl-md-4 pr-md-4 pt-md-4 pl-lg-0 pr-lg-0 pt-lg-0">
                {/* <h5 className="d-flex d-lg-flex d-md-flex d-sm-flex d-xl-flex justify-content-between mt-0 mt-lg-5 mt-md-0 mt-sm-0 pl-5"> */}
                Filter By <span className="float-right d-sm-inline-block d-lg-none d-md-inline-block d-xl-none d-inline-block ml-2"
                    onClick={openSearchDialogHandler} >&#10006;</span> </h4>
            {ExperienceLevel && <div className="menu-filter-section resFloat pr-5 pr-sm-5 pr-lg-0 pr-xl-0 pr-md-0 pl-5 pl-sm-5 pl-lg-0 pl-xl-0 pl-md-4">
                <span className="pb-2 pt-3">Experience Level</span>
                <ul className="p-0">
                    <li>
                        <FormGroup className="list-filter">
                            {ExperienceLevel ? ExperienceLevel.map(data => {
                                return (
                                    <FormControlLabel
                                        key={data.id}
                                        className="list-filtered-data"
                                        // control={<Checkbox checked={(props.experience).includes((data.id).toString())} name="experience" value={data.id} />}
                                        control={<Checkbox name="experience"
                                            // checked={selectedExperience.length && selectedExperience.filter(r=>r.id==data.id)?true:false}
                                            checked={selectedExperience.filter(r => r.id == data.id).length ? true : false}
                                            value={data.id} />}
                                        label={data.startRange == "8" ? data.name + " (" + data.startRange + "+" + " Years" + ")" : data.name + " (" + data.startRange + "-" + data.endRange + " Years" + ")"}
                                        color="primary"
                                        onChange={onChangeHandler}
                                    />
                                )
                            }
                            ) : ""}

                        </FormGroup>
                    </li>
                </ul>
                <hr className="d-block d-sm-block d-md-none d-lg-block border opacity1 text-left w-75 m-0" />
            </div>}

            <div className="menu-filter-section resFloat pr-5 pr-sm-5 pr-lg-0 pr-xl-0 pr-md-0 pl-5 pl-sm-5 pl-lg-0 pl-xl-0 pl-md-5">
                <span className="pb-2 pt-3">Sorted By</span>
                <ul className="p-0">
                    <li>
                        <FormGroup>
                            <RadioGroup row name="row-radio-buttons-group"
                                value={relDefaultValue}
                            >
                                {Sort.map(data => <FormControlLabel
                                    key={data.label}
                                    className="list-filtered-data w-100"
                                    control={<Radio color="primary" />}
                                    value={data.label}
                                    name={data.label}
                                    label={data.label}
                                    color="#F79433"
                                    onChange={releventSort} />
                                )}
                            </RadioGroup>
                        </FormGroup>
                    </li>
                </ul>
                <hr className="d-block d-sm-block d-md-none d-lg-block border opacity1 text-left w-75 m-0" />
            </div>
            <div className="menu-filter-section  pr-5 pr-sm-5 pr-lg-0 pr-xl-0 pr-md-0 pl-5 pl-sm-5 pl-lg-0 pl-xl-0 pl-md-2">
                <span className="pb-2 pt-3">Experience</span>
                <ul className="p-0">
                    <li>
                        <FormGroup>
                            <RadioGroup row name="row-radio-buttons-group"
                                value={expDefaultValue}
                            >
                                {experienceSorting.map(data => <FormControlLabel
                                    key={data.label}
                                    className="list-filtered-data w-100"
                                    control={<Radio color="primary" />}
                                    value={data.label}
                                    name={data.label}
                                    label={data.label}
                                    color="#F79433"
                                    onChange={experienceSort} />
                                )}

                            </RadioGroup>
                        </FormGroup>
                    </li>
                </ul>
                <hr className="d-block d-sm-block d-md-none d-lg-block border opacity1 text-left w-75 m-0" />
            </div>

            {props.candidateSkillsSuggestion.length ?
                <div className="menu-filter-section pr-5 pr-sm-5 pr-lg-0 pr-xl-0 pr-md-5 pl-5 pl-sm-5 pl-lg-0 pl-xl-0 pl-md-4">
                    <span className="pb-2 pt-3">Skills</span>
                    <ul className="p-0">
                        <li>
                            <FormGroup>
                                {props.candidateSkillsSuggestion.map((data) => {
                                    return (<FormControlLabel
                                        // disabled={data.alias ? props.searchSkills.includes(data.alias) ? true : false : props.searchSkills.includes(data.label) ? true : false}
                                        key={data.alias ? data.alias : data.label}
                                        className="list-filtered-data"
                                        control={<Checkbox
                                            checked={selectedSkill != undefined ? data.alias ? selectedSkill.includes(data.alias) ? true : false :
                                                selectedSkill.includes(data.label) ? true : false : false}
                                            name="skills" value={data.alias ? data.alias : data.label} />}
                                        id="data.skillId"
                                        label={data.alias ? data.alias : data.label}
                                        // label={data.alias ? data.alias : data.label}
                                        color="primary"
                                        name='skills'
                                        onChange={skillsFilterHandler}
                                    />
                                    )
                                })}
                                {/* {moreLess == "show more" ? */}
                                <span className="mt-3 more-less font-weight-bold" onClick={showMoreLess}>{moreLess}</span>
                                {/* : ""} */}
                            </FormGroup>
                        </li>
                    </ul>
                    <hr className="d-block d-sm-block d-md-none d-lg-block border opacity1 text-left w-75 m-0" />
                </div> : null}

        </div>
    );
}
const mapStateToProps = state => {
    return {
        candidateData: state.candidate.candidateList,
        masterCandidateData: state.candidate.candidateMaster,
        candidateTotalSkills: state.candidate.candidateTotalSkills,
        candidateTotalSkillSuggestionMaster: state.candidate.candidateTotalSkillSuggestionMaster,
        candidateSkillsSuggestion: state.candidate.candidateSkillsSuggestion,
        candidateDataLimit: state.candidate.candidateDataLimit,
        searchRole: state.candidateSearch.searchRole,
        searchCityName: state.candidateSearch.searchCityName,
        searchJob: state.candidateSearch.searchJob,
        searchSkills: state.candidateSearch.searchSkills,
        searchZipCode: state.candidateSearch.searchZipCode,
        searchRadius: state.candidateSearch.searchRadius,
        clearFilter: state.candidateSearch.clearFilter,
        clearLoaderFilter: state.candidateSearch.clearLoaderFilter,
        candidateExperienceLevel: state.candidateSearch.candidateExperienceLevel,
        releventTitleStatus: state.candidate.releventTitleStatus,
        searchDialogStatus: state.searchDataDialog.searchDialogStatus,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCandiadteMaster: (jobId) => dispatch(setCandiadteMaster(jobId)),
        setCandidateSearchedData: (jobId) => dispatch(setCandidateSearchedData(jobId)),
        setCandidateTotalSkills: (data) => dispatch(setCandidateTotalSkills(data)),
        setCandidateCount: (data) => dispatch(setCandidateCount(data)),
        setCandidateTotal: (data) => dispatch(setCandidateTotal(data)),
        setCandidateTotalSkillSuggestionMaster: (data) => dispatch(setCandidateTotalSkillSuggestionMaster(data)),
        setSkillSuggestionsList: (data) => dispatch(setSkillSuggestionsList(data)),
        setSearchCity: (data) => dispatch(setSearchCity(data)),
        setSearchJob: (data) => dispatch(setSearchJob(data)),
        setSearchSkills: (data) => dispatch(setSearchSkills(data)),
        setClearFilter: (jobId) => dispatch(setClearFilter(jobId)),
        setClearExperienceLoader: (jobId) => dispatch(setClearExperienceLoader(jobId)),
        setCandidateLimit: (jobId) => dispatch(setCandidateLimit(jobId)),

        setSearchRole: (data) => dispatch(setSearchRole(data)),
        setSearchZipCode: (data) => dispatch(setSearchZipCode(data)),
        setSearchRadius: (data) => dispatch(setSearchRadius(data)),
        setCandidateCardSkills: (data) => dispatch(setCandidateCardSkills(data)),
        setSelectedSkills: (jobId) => dispatch(setSelectedSkills(jobId)),
        networkErrorDialogToggle: (toggleStatus) => dispatch(networkErrorDialogToggle(toggleStatus)),
        setCandidateExperienceLevel: (toggleStatus) => dispatch(setCandidateExperienceLevel(toggleStatus)),
        setReleventTitleStatus: (data) => dispatch(setReleventTitleStatus(data)),
        setExperienceStatus: (data) => dispatch(setExperienceStatus(data)),
        setCandiadteSelectedId: (jobId) => dispatch(setCandiadteSelectedId(jobId)),
        searchDataDialogToggle: (jobId) => dispatch(searchDataDialogToggle(jobId)),
        setSearchSideSkills: (data) => dispatch(setSearchSideSkills(data)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar)

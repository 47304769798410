import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { setCandidateSearchedData, setCandiadteSelectedId, setCandidateCount, allSkillsDataDialogToggle, setCandidateData } from "../../redux";
import { jobFilter, candidateDetail } from "../../_service/jobListing";
import { connect } from "react-redux";
import Popover from '@material-ui/core/Popover';
import AllSkillsDataDialog from "../Dialog/AllSkillsDataDialog";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
    textAlign: "left",
    color: " #808080",
    opacity: "0.7",
    flex: "none",
    "& > span": {
      float: "right",
      padding: "0px",
      position: "relative",
      right: "0",
      top: "-5px"
    }

  },
  pos: {
    marginBottom: 12,
    width: "50%",
    textAlign: "left",
    marginTop: "1rem",
    fontSize: "0.8rem",
    fontWeight: "500"
  },
});
function DashboardCards(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const [selectedSkill, setSelectedSkills] = React.useState([]);
  const [selectedData, setSelectedData] = useState([])
  const [limit, setLimit] = useState(216);
  const [spinner, setSpinner] = useState(false);

  function cardSelect(e, id) {
    let candidateData = [...props.candidateData]
    if (props.candidateSelectedData.length <= 4) {
      if (props.candidateSelectedData.find(d => id == d.refId)) {
        let a = props.candidateSelectedData.filter(r => r.refId != id);
        props.setCandiadteSelectedId([...a])
        setSelectedData([a])
      } else {
        let filteredData = candidateData.find(d => d.refId == id)
        setSelectedData([...selectedData, filteredData])
        props.setCandiadteSelectedId([...props.candidateSelectedData, filteredData])
      }

    } else {
      if (props.candidateSelectedData.find(d => id == d.refId)) {
        let a = props.candidateSelectedData.filter(r => r.refId != id);
        props.setCandiadteSelectedId([...a])
        setSelectedData([...a])
      }
    }
  }

  // _______________SKILLS DIALOG OPEN____________________

  function openSkillDialog(e, id) {
    props.allSkillsDataDialogToggle(!props.skillDialogStatus)
    candidateDetail({ 'candidateId': id }).then(function (response) {
      if (response.data) {
        let r = response.data
        let address = r.city.cityName ? r.city.cityName : "" + r.country.country ? "," + r.country.country : ""
        let stateValue = r.state.stateName ? r.state.stateName : ""
        let cityValue = r.city.cityName ? r.city.cityName : ""
        let detail = {
          refId: r.refId,
          yearOfExp: r.workExperience,
          zipCode: r.zipCode.zipCode,
          cityCountry: address,
          city: cityValue,
          state: stateValue,
          skills: r.skills,
          jobTitle: r.job.jobTitle
        }
        props.setCandidateData(detail)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(JSON.stringify(error));
      console.log(error.response);
      // props.networkErrorDialogToggle(!props.dialogStatus);

    });

  }
  console.debug("dashboard card data", props.candidateData)

  return (
    <>
      <div id="candidateCard" className="card_wrapper row"  >
        {props.candidateData.length ? props.candidateData.map((data, key) => (
          <div key={data.refId} className="col-md-6 col-sm-12 col-lg-4 col-xl-3 mt-4">
            <Card className={(props.candidateSelectedData.find((val) => { return val.refId == data.refId })) ? "card_container min-height-235" : "min-height-235"}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Ref No- {data.refId}
                  <Checkbox
                    checked={(props.candidateSelectedData.find((val) => { return val.refId == data.refId })) ? true : false}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    onChange={(e) => cardSelect(e, data.refId)}
                  />
                </Typography>
                <Typography title={data.job.jobTitle} className={(props.candidateSelectedData.find((val) => { return val.refId == data.refId })) ? "color_F79433 subTitle dotsAdd" : "dotsAdd subTitle"} variant="h5" component="h2">
                  {data.job.jobTitle}
                </Typography>
                <div className="detail-content ">
                  <Typography className={classes.pos} color="textSecondary">
                    <span> Year of exp:</span>
                    <span>{data.workExperience}</span>
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <span>Zipcode:</span>
                    <span>{data.zipCode.zipCode ? data.zipCode.zipCode : "-"}</span >
                  </Typography>

                </div>
                {/* <div className="detail-content ">
                  <Typography className={classes.pos + ' mt-2'} color="textSecondary">
                    <span> City,Country:</span>
                    <span>{data.city?.cityName + "," + data.country?.country}</span >
                  </Typography>
                </div> */}
                <div className="detail-content ">
                  <Typography className={classes.pos + ' mt-2'} color="textSecondary">
                    <span> City,State:</span>
                    <span>{data.city.cityName != null ? data.city.cityName : "-"}
                      ,
                      {data.state.stateName != null ? data.state.stateName : "-"}</span >
                    {/* <span>{data.city?.cityName + "," + data.state?.stateName}</span > */}
                  </Typography>
                </div>
                <div className="bottom-10 position-absolute text-right w-75">
                  <button onClick={(e) => openSkillDialog(e, data.refId)}
                    className="bg-white border border-white font-weight-bold p-0 rounded secondaryColor small">View Skills Detail</button>
                </div>
              </CardContent>
            </Card>
          </div>
        )

        )
          :
          <div className="align-items-center height-69 w-100 justify-content-center text-center d-flex">
            <div className="row d-block">
              <p className="font-weight-bold text-black-50 pb-1">No Data Found.    </p>
              <span className="font-weight-bold small text-black-50 text-justify ml-1">
                Although a matching candidate profile was not found, we can still help and provide additional candidates with your desired skills.
                <br /> Please select the "No Matching Profiles” button at the top right corner of the page to proceed.</span>
            </div>
          </div>
        }
        <AllSkillsDataDialog />

      </div>
    </>
  );
}
const mapStateToProps = state => {
  return {
    candidateData: state.candidate.candidateList,
    candidateSelectedData: state.candidateSelectedId.selectedFile,
    masterCandidateData: state.candidate.candidateMaster,

    searchRole: state.candidateSearch.searchRole,
    searchCityName: state.candidateSearch.searchCityName,
    searchJob: state.candidateSearch.searchJob,
    searchSkills: state.candidateSearch.searchSkills,
    searchZipCode: state.candidateSearch.searchZipCode,
    searchRadius: state.candidateSearch.searchRadius,
    skillDialogStatus: state.candidateAllSkills.skillDialogStatus

  }
}
const mapDispatchToProps = dispatch => {
  return {
    // setCandidateSearchedData: (jobId) => dispatch(setCandidateSearchedData(jobId)),
    setCandidateCount: (data) => dispatch(setCandidateCount(data)),
    setCandiadteSelectedId: (jobId) => dispatch(setCandiadteSelectedId(jobId)),
    allSkillsDataDialogToggle: (jobId) => dispatch(allSkillsDataDialogToggle(jobId)),
    setCandidateData: (toogleStatus) => dispatch(setCandidateData(toogleStatus)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardCards)

import React from 'react';
import { searchDataDialogToggle } from '../../redux'
import { connect } from "react-redux";
import DashboardSearch from '../Dashboard/dashboardSearch';


function SearchDialoge(props) {

  return (
    <div>
        <DashboardSearch />
    </div>
  );
}

// export default FileRenameDialog;
const mapStateToProps = state => {
  return {
    searchDialogStatus: state.searchDataDialog.searchDialogStatus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchDataDialogToggle: (jobId) => dispatch(searchDataDialogToggle(jobId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDialoge)

export const SET_SEARCH_ROLE = 'SET_SEARCH_ROLE'
export const SET_SEARCH_CITY_NAME = 'SET_SEARCH_CITY_NAME'
export const SET_SEARCH_JOB = 'SET_SEARCH_JOB'
export const SET_SEARCH_SKILLS = 'SET_SEARCH_SKILLS'
export const SET_SEARCH_ZIP_CODE = 'SET_SEARCH_ZIP_CODE'
export const SET_SEARCH_RADIUS = 'SET_SEARCH_RADIUS'
export const SET_CLEAR_FILTER = 'SET_CLEAR_FILTER'
export const SET_CLEAR_EXPERIENCE_LOADER = 'SET_CLEAR_EXPERIENCE_LOADER'
export const SET_SEARCH_STATUS_TRIGGER = 'SET_SEARCH_STATUS_TRIGGER'
export const SET_CANDIDATE_EXPERIENCE_LEVEL = 'SET_CANDIDATE_EXPERIENCE_LEVEL'
export const SET_SEARCH_SIDE_SKILLS = 'SET_SEARCH_SIDE_SKILLS'

// export const SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST = 'SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST'
// export const SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS = 'SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS'
// export const SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE = 'SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE'

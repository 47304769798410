import {
  SET_CANDIDATE_SELECTED_ID,
  SET_SELECTED_CANDIDATE_DATA
} from './candidateSelectedTypes'

export const setCandiadteSelectedId = (jobId) => {
  return {
      type: SET_CANDIDATE_SELECTED_ID,
      payload:jobId,
  }
}

export const fetchSelectedCandidateData = (data) => {
  return {
      type: SET_SELECTED_CANDIDATE_DATA,
      payload:data,
  }
}




import {
  SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST,
  SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS,
  SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE,
  SET_SEARCH_ROLE,
  SET_SEARCH_CITY_NAME,
  SET_SEARCH_JOB,
  SET_SEARCH_SKILLS,
  SET_SEARCH_ZIP_CODE,
  SET_SEARCH_RADIUS,
  SET_CLEAR_FILTER,
  SET_CLEAR_EXPERIENCE_LOADER,
  SET_SEARCH_STATUS_TRIGGER,
  SET_CANDIDATE_EXPERIENCE_LEVEL,
  SET_SEARCH_SIDE_SKILLS
} from './candidateSearchTypes'

const initialState = {
  searchRole: null,
  searchCityName: null,
  searchJob: null,
  searchSkills: null,
  searchZipCode: null,
  searchRadius: null,
  clearFilter: "",
  clearLoaderFilter: "",
  searchStatusTrigger: false,
  candidateExperienceLevel: [],
  searchedSideSkills:[]
};

function candidateSearch(state = initialState, action) {
  switch (action.type) {

    // case SET_CANDIDATE_TOTAL_COUNT_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case SET_CANDIDATE_TOTAL_COUNT_DATA_SUCCESS:
    //   console.debug("candidate success data ", action.payload)
    //   return {
    //     loading: false,
    //     searchRole: action.payload.job,
    //     searchCityName: action.payload.cityName,
    //     searchJob: action.payload.job,
    //     searchZipCode: action.payload.zipCode,
    //     searchRadius: action.payload.radius,
        
    //     error: ''
    //   }
    //   break;

    // case SET_CANDIDATE_TOTAL_COUNT_DATA_FAILURE:
    //   return {
    //     loading: false,
    //     error: action.payload
    //   }
    //   break;


    case SET_SEARCH_ROLE:

      {
        return {
          ...state,
          searchRole: action.payload
        }
      }
    case SET_SEARCH_CITY_NAME:
      {
        return {
          ...state,
          searchCityName: action.payload
        }
      }
    case SET_SEARCH_JOB:
      {
        return {
          ...state,
          searchJob: action.payload
        }
      }
    case SET_SEARCH_SKILLS:
      {
        return {
          ...state,
          searchSkills: action.payload
        }
      }
    case SET_SEARCH_ZIP_CODE:
      {
        return {
          ...state,
          searchZipCode: action.payload
        }
      }

    case SET_SEARCH_RADIUS:
      {
        return {
          ...state,
          searchRadius: action.payload
        }
      }
    case SET_CLEAR_FILTER:
      {
        return {
          ...state,
          clearFilter: action.payload
        }
      }
    case SET_CLEAR_EXPERIENCE_LOADER:
      {
        return {
          ...state,
          clearLoaderFilter: action.payload
        }
      }
    case SET_SEARCH_STATUS_TRIGGER: {
      return {
        ...state,
        searchStatusTrigger: action.payload
      }
    }
    case SET_CANDIDATE_EXPERIENCE_LEVEL: {
      return {
        ...state,
        candidateExperienceLevel: action.payload
      }
    }
    case SET_SEARCH_SIDE_SKILLS:{
      return {
        ...state,
        searchedSideSkills: action.payload
      }
    }
    
    default:
      return state
  }
}

export default candidateSearch;
import React, { useState, useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import { Link, NavLink,useHistory } from "react-router-dom";

function ThankYou() {
    let history = useHistory();

    function moveLink(e) {
        window.location.reload();
        history.push("/");

    }

    return (
        <div className=" main-container position-absolute w-100">
            <div className="submitform-container  position-relative w-100 d-flex align-items-start flex-row">

                <div className="align-items-start d-flex flex-column height-69 p-4 p-lg-5 p-md-4 p-sm-4 thank-you-container">
                    <Typography className="heading-bold font-weight-bold mt-4" color="textSecondary" gutterBottom>
                        Thank you for the information
                    </Typography>
                    <Typography className="subHead" color="textSecondary" gutterBottom>
                        Our representative will be in touch. Feel free to explore more.
                    </Typography>
                    <div className="submit-btn mt-2">
                        {/* <button className="search colorWhite" onClick={moveLink}><Link to="/" className="colorWhite">Search again </Link></button> */}
                        <button className="search colorWhite" onClick={moveLink}><a className="colorWhite">Search again </a></button>
                    </div>
                </div>
                <div className="image-container  d-sm-none d-lg-block d-md-none d-xl-block d-none">
                    <img src="./img/GraphicWorker.svg" />
                </div>
            </div>
        </div>

    );
}

export default ThankYou;
import {
  SET_CANDIDATE_SEARCHED_DATA_REQUEST,
  SET_CANDIDATE_SEARCHED_DATA_SUCCESS,
  SET_CANDIDATE_SEARCHED_DATA_FAILURE,
  SET_CANDIDATE_SEARCHED_DATA,
  SET_CANDIDATE_TOTAL,
  SET_CANDIDATE_COUNT,
  SET_CANDIDATE_MASTER,
  SET_CANDIDATE_TOTAL_SKILLS,
  SET_CANDIDATE_TOTAL_SKILLS_MASTER,
  SET_CANDIDATE_CARD_SKILLS,
  SET_SKILL_SUGGESTIONS_LIST,
  SET_CANDIDATE_LIMIT,
  SET_SELECTED_SKILL_SUGGESTION_OBJECT,
  SET_SELECTED_SKILLS,
  SET_SKILLS_INFO,
  SET_RELEVENT_TITLE_STATUS,
  SET_EXPERIENCE_STATUS,
  SET_REMOTE_STATUS
} from './candidateTypes'
import {  jobFilter } from "../../_service/jobListing";

// export const setCandidateSearchedData = (data) => {
//   console.debug("candidate action fetch data", data)
//   return (dispatch) => {
//     dispatch(setCandidateSearchedDataRequest())
//     jobFilter(data)
//       .then(response => {
//         const candidate = response.data
//         dispatch(setCandidateSearchedDataSuccess(candidate))
//       })
//       .catch(error => {
//         dispatch(setCandidateSearchedDataFailure(error.message))
//       })
//   }
// }

// export const setCandidateSearchedDataRequest = () => {
//   return {
//     type: SET_CANDIDATE_SEARCHED_DATA_REQUEST
//   }
// }

// export const setCandidateSearchedDataSuccess = data => {
//   console.debug(" candidate data action", data)
//   return {
//     type: SET_CANDIDATE_SEARCHED_DATA_SUCCESS,
//     payload: data
//   }
// }

// export const setCandidateSearchedDataFailure = error => {
//   return {
//     type: SET_CANDIDATE_SEARCHED_DATA_FAILURE,
//     payload: error
//   }
// }


export const setCandidateSearchedData = (jobId) => {
  return {
      type: SET_CANDIDATE_SEARCHED_DATA,
      payload:jobId,
  }
}
export const setCandidateLimit = (data) => {
  return {
    type: SET_CANDIDATE_LIMIT,
    payload: data,
  }
}
export const setSkillSuggestionsList = (data) => {
  return {
    type: SET_SKILL_SUGGESTIONS_LIST,
    payload: data,
  }
}

export const setCandiadteMaster = (data) => {
  return {
    type: SET_CANDIDATE_MASTER,
    payload: data,
  }
}

export const setCandidateTotal = (list) => {
  return {
    type: SET_CANDIDATE_TOTAL,
    payload: list,
  }
}

export const setCandidateCount = (count) => {
  return {
    type: SET_CANDIDATE_COUNT,
    payload: count,
  }
}

export const setCandidateTotalSkills = (data) => {
  return {
    type: SET_CANDIDATE_TOTAL_SKILLS,
    payload: data,
  }
}

export const setCandidateTotalSkillSuggestionMaster = (data) => {
  return {
    type: SET_CANDIDATE_TOTAL_SKILLS_MASTER,
    payload: data,
  }
}
export const setSelectedSkillSuggestionArrayObject = (data) => {
  return {
    type: SET_SELECTED_SKILL_SUGGESTION_OBJECT,
    payload: data,
  }
}

export const setCandidateCardSkills = (data) => {
  return {
    type: SET_CANDIDATE_CARD_SKILLS,
    payload: data,
  }
}

export const setSelectedSkills = (data) => {
  return {
    type: SET_SELECTED_SKILLS,
    payload: data,
  }
}

export const setSkillInfo = (data) => {
  return {
    type: SET_SKILLS_INFO,
    payload: data,
  }
}

export const setReleventTitleStatus = (data) => {
  return {
    type: SET_RELEVENT_TITLE_STATUS,
    payload: data,
  }
}
export const setExperienceStatus = (data) => {
  return {
    type: SET_EXPERIENCE_STATUS,
    payload: data,
  }
}

export const setRemoteStatus = (data) => {
  return {
    type: SET_REMOTE_STATUS,
    payload: data,
  }
}
import axios from "axios";

export async function getExperienceList() {

    const response = await axios.get(
        "https://staffing-admin.mastechdigital.com/api/experience-setting",

    ).then(function (response) {
        console.log(" ROle create success response");
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });

    return response;
}
import axios from "axios";

export async function getSkillsSuggestion(title,type) {
    // title=java d&type=jobtitle
 
    let paramTempObj={};
    paramTempObj.title=title;
    paramTempObj.type=type;
    const response = await axios.post(
        "https://staffing-admin.mastechdigital.com/api/suggestion?",
                paramTempObj

    ).then(function (response) {
        console.log(" ROle create success response");
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });

    return response;
}
import axios from 'axios'
import {
  NETWORK_ERROR_DIALOG_TOGGLE,
} from './networkErrorDialogTypes'

export const networkErrorDialogToggle = (toggleStatus) => {
  return {
    type: NETWORK_ERROR_DIALOG_TOGGLE,
    payload: toggleStatus
  }
}

